import React, { useEffect } from "react";
import StandardHeader from "../../component/Common/StandardHeader";
import ProductsCategoryTable from "../../component/Admin/ProductsCategoryTable";
import { ModalService, ModalRoot } from "../../component/Common/Modal";
import { AddIcon } from "../../svgs/Index";
import Button from "../../component/Common/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  addCategory,
  fetchCategories,
} from "../../features/Categories/CategoriesThunk";
import CommonModal from "../../component/Common/CommonModal";
import { useTranslation } from "react-i18next";

const ProductsCategory = () => {
  const dispatch = useDispatch();
  const categories = useSelector((state) => state.categories.categories);

  useEffect(() => {
    dispatch(fetchCategories());
  }, [dispatch]);

  const openAddCategoryModal = () => {
    ModalService.open(CommonModal, {
      title: `${t('Add Category')}`,
      inputName: "categoryName",
      inputDescription: "categoryDescription",
      action: addCategory,
      type: "add",
    });
  };

  const { t } = useTranslation();

  return (
    <div className="mt-6 bg-white min-h-[80vh] rounded-md shadow-md p-4">
      <StandardHeader label={`${t('Products Category')}`}>
        <Button
          onClick={openAddCategoryModal}
          buttonStyle={"bg-primary text-white"}
        >
          <AddIcon className="w-4 h-4" /> {t('Add Category')}
        </Button>
      </StandardHeader>
      <ProductsCategoryTable categories={categories} />
      <ModalRoot />
    </div>
  );
};

export default ProductsCategory;
