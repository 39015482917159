import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const addUser = createAsyncThunk(
  "addUser/createUser",
  async ({ userData, onSucces, onError }) => {
    try {
      console.log(userData);
      const response = await axios.post("/api/users/signup", userData);
      onSucces();
      return response?.data?.data.user;
    } catch (err) {
      onError({ error: true, message: err.response.data.message });
      return { error: true, message: err.response.data.message };
    }
  }
);

export const fetchUsers = createAsyncThunk(
  "addUser/fetchUsers",
  async (userData) => {
    try {
      const response = await axios.get("/api/users/all-users", userData);
      return response?.data;
    } catch (err) {
      throw err;
    }
  }
);

export const deleteUser = createAsyncThunk(
  "addUser/deleteUser",
  async ({ id }, { dispatch }) => {
    try {
      await axios.delete(`/api/users/delete/${id}`);
      dispatch(fetchUsers());
    } catch (err) {
      throw err;
    }
  }
);
export const updateUser = createAsyncThunk(
  "addUser/updateUser",
  async (userData, { dispatch }) => {
    try {
      await axios.put(`/api/users/update/${userData.id}`, userData);
      dispatch(fetchUsers());
    } catch (err) {
      throw err;
    }
  }
);
