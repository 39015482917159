// productCatalog.js
import React from "react";

function ProductCatalog({ productCatalog }) {
    return (
        <div className="productCatalog">
            <img src={productCatalog.imageUrl} width="200" height="150" alt={productCatalog.name} />
            <h2>{productCatalog.name}</h2>
            <p>{productCatalog.description}</p>
            {/*<p>${productCatalog.price.toFixed(2)}</p>*/}
            {/*<button>Add to Cart</button>*/}
        </div>
    );
}

export default ProductCatalog;
