import React, { useState, useEffect } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "../Common/Modal";
import Button from "../Common/Button";
import UserAvatar from "../Common/UserAvatar";
import { useDispatch, useSelector } from "react-redux";
import { updateSingleUser } from "../../features/UserAuthSlice";
import { useTranslation } from "react-i18next";
import { useTransition } from "react";

const EditProfileModal = ({
  close,
  label,
  placeholder,
  username,
  formData,
  updateFormData,
  field,
}) => {
  const [newValue, setNewValue] = useState(formData[field]);
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.userAuth?.user);

  const handleSave = () => {
    try {
      dispatch(updateSingleUser({ id: user?._id, [field]: newValue }));
      updateFormData(field, newValue);
      close();
    } catch (err) { }
  };
  const handleChange = (e) => setNewValue(e.target.value);
  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (e.target.classList.contains("modal-overlay")) {
        close();
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [close]);

  const { t } = useTranslation();


  return (
    <Modal>
      <ModalHeader>{t('Edit User')}</ModalHeader>
      <ModalBody>
        <div className="flex justify-center">
          <UserAvatar name={username} />
        </div>
        {/* Username input */}
        <div className="mb-4 mt-4">
          <label className="block text-sm font-medium text-gray-700">
            {label}
          </label>
          <input
            type="text"
            className="mt-1 px-3 py-2 w-full focus:outline-none border rounded-md focus:ring focus:ring-indigo-200"
            placeholder={placeholder}
            value={newValue}
            onChange={handleChange}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="flex justify-end gap-2">
          <Button buttonStyle="text-gray-700 bg-gray-200 " onClick={close}>
            {t('Cancel')}
          </Button>
          <Button onClick={handleSave} buttonStyle="text-white bg-primary">
            {t('Save')}
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default EditProfileModal;
