import React, { useEffect } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "../../Common/Modal";
import demoImage from "../../../assets/images/demo-1.jpg";
import Button from "../../Common/Button";
import { useDispatch } from "react-redux";
import { deleteUser } from "../../../features/UserAdd/UserAddThunk";

export const DeleteUserModal = ({ close, foundUser }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (e.target.classList.contains("modal-overlay")) {
        close();
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [close]);
  const handleUserDelete = async () => {
    try {
      dispatch(deleteUser({ id: foundUser._id }));
      close();
    } catch (err) {
      throw err;
    }
  };
  return (
    <Modal>
      <ModalHeader>Delete User</ModalHeader>
      <ModalBody>
        <div className="flex items-center gap-4 pb-4 border-b">
          <img
            className="w-12 h-12 rounded-full object-cover"
            src={demoImage}
            alt="Profile"
          />
          <h1 className="font-semibold text-lg">{foundUser?.userName}</h1>
        </div>
        <div className="pt-3 pb-8 flex flex-col gap-4 border-b">
          <div className="flex justify-between">
            <h2 className="font-semibold">email</h2>
            <p>{foundUser?.email}</p>
          </div>
          <div className="flex justify-between">
            <h2 className="font-semibold">User Role</h2>
            <p>{foundUser?.userRole}</p>
          </div>
          <div className="flex justify-between">
            <h2 className="font-semibold">Department</h2>
            <p>{foundUser?.department}</p>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="flex mt-6 justify-end gap-2">
          <Button buttonStyle="text-gray-700 bg-gray-200 " onClick={close}>
            Cancel
          </Button>
          <Button
            onClick={handleUserDelete}
            buttonStyle="mr-2  text-white bg-[#D20101]"
          >
            Delete
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};
