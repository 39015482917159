import React, { useEffect } from "react";
import StandardHeader from "../../component/Common/StandardHeader";
import SupplierTable from "../../component/Admin/SupplierTable";
import { AddIcon } from "../../svgs/Index";
import Button from "../../component/Common/Button";
import { ModalRoot, ModalService } from "../../component/Common/Modal";
import { useSelector, useDispatch } from "react-redux";
import {
  addSupplier,
  fetchSuppliers,
} from "../../features/Supplier/SupplierThunk";
import CommonModal from "../../component/Common/CommonModal";

import { useTranslation } from "react-i18next";

const Supplier = () => {
  const dispatch = useDispatch();
  const supplierData = useSelector((state) => state.suppliers.suppliers);
  const openSupplierModal = () => {
    ModalService.open(CommonModal, {
      title: `${t('Add Supplier')}`,
      type: "add",
      inputName: "supplierName",
      inputDescription: "supplierDescription",
      action: addSupplier,
    });
  };
  useEffect(() => {
    dispatch(fetchSuppliers());
  }, []);

  const { t } = useTranslation();
  return (
    <div className="mt-6 bg-white min-h-[80vh] rounded-md shadow-md p-4">
      <StandardHeader label={t('Suppliers')}>
        <Button
          onClick={openSupplierModal}
          buttonStyle="bg-primary text-white "
        >
          <AddIcon className="w-4 h-4" /> {t('Add Supplier')}
        </Button>
      </StandardHeader>
      <SupplierTable supplierData={supplierData} />
      <ModalRoot />
    </div>
  );
};

export default Supplier;
