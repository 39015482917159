import React from "react";
import axios from "../utils/axiosConfig";
import { useNavigate } from "react-router-dom";
import { set, useForm } from "react-hook-form";
import { ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { notifyAndNavigate, notify } from "../utils/notify";
import { loginSuccess } from "../features/UserAuthSlice";
import Logo from "../component/Common/Logo";
import FormInput from "../component/Common/FormInput";
import { useState } from "react";

const SignIn = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const { email, password } = data;
      const { data: response } = await axios.post("/api/users/login", {
        email,
        password,
      });

      dispatch(loginSuccess(response.data));
      const userType = response?.data?.user?.userRole;
      if (response.status === "success") {
        localStorage.setItem("token", response?.data?.token);
        if (userType === "admin") {
          notifyAndNavigate(navigate, "Sign-in successful!", "/system");
          setLoading(false);
        } else if (userType === "manager") {
          notifyAndNavigate(navigate, "Sign-in successful!", "/system/report");
        } else {
          notifyAndNavigate(navigate, "Sign-in successful!", "/system/inventory");
        }
      }
    } catch (err) {
      setLoading(false);
      notify("wrong credentials");
    }
  };

  return (
    <div className="bg-white h-screen flex items-center justify-center">
      <div className="max-h-fit pb-16 pt-8 rounded-md shadow-[rgba(0,0,15,0.1)_0px_1px_4px_2px]  xl:w-[55%] lg:w-[65%] w-[90%] mx-auto overflow-hidden">
        <div>
          <div className="flex items-center justify-center mb-10">
            <Logo styles={"text-xl"} />
          </div>
          <div className="w-[80%] mx-auto border min-h-full lg:px-8 py-8">
            <form
              className="md:w-[60%] w-[90%] flex text-sm flex-col gap-2 mx-auto"
              onSubmit={handleSubmit(onSubmit)}
            >
              <FormInput
                label="Email"
                type="text"
                placeholder="Enter your email"
                id="email"
                register={register("email", {
                  required: "Email is required",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Invalid email address",
                  },
                })}
                error={errors.email && errors.email.message}
              />

              <FormInput
                label="Password"
                type="password"
                placeholder="Enter your password"
                id="password"
                register={register("password", { required: true })}
                error={errors.password && "Password is required"}
              />

              <button
                disabled={loading}
                className="w-full font-medium bg-primary rounded-md py-2 mt-2 text-white"
                type="submit"
              >
                Sign In
              </button>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer autoClose={2000} position="top-center" />
    </div>
  );
};

export default SignIn;
