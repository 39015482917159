import { useState, useEffect } from "react";

export function ModalRoot() {
  const [modal, setModal] = useState({});
  useEffect(() => {
    ModalService.on("open", ({ component, props }) => {
      setModal({
        component,
        props,
        close: () => {
          setModal({});
        },
      });
    });
  }, []);

  const ModalComponent = modal.component ? modal.component : null;

  return (
    <section className={modal.component ? "modalRoot" : ""}>
      {ModalComponent && (
        <ModalComponent
          {...modal.props}
          close={modal.close}
          className={ModalComponent ? "d-block" : ""}
        />
      )}
    </section>
  );
}
export function Modal(props) {
  return (
    <div className="modal-overlay fixed inset-0 flex items-center justify-center z-50 bg-opacity-50 bg-gray-900">
      <div className="bg-white w-[90%] md:w-[60%] lg:w-[40%] px-8 py-6 rounded-lg shadow-lg">
        {props.children}
      </div>
    </div>
  );
}
export function ModalBody(props) {
  return <div className="">{props.children}</div>;
}
export function ModalHeader(props) {
  return (
    <h2 className="text-lg relative after:bg-slate-600 after:w-[20%] after:content-[''] after:left-[50%] after:-translate-x-[50%] after:h-1 after:absolute after:-bottom-4 font-semibold mb-8 text-center">
      {props.children}
    </h2>
  );
}
export function ModalFooter(props) {
  return <div className="">{props.children}</div>;
}
export const ModalService = {
  on(event, callback) {
    document.addEventListener(event, (e) => callback(e.detail));
  },
  open(component, props = {}) {
    document.dispatchEvent(
      new CustomEvent("open", { detail: { component, props } })
    );
  },
};
