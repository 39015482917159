import React from "react";
import { SelectIcon } from "../../svgs/Index";
const SelectInput = ({
  label,
  options,
  placeholder,
  register,
  name,
  value,
  onChange,
}) => {
  return (
    <div className="flex flex-col gap-2">
      <label className="block text-sm font-medium text-gray-700">{label}</label>
      <div className="relative">
        <select
          onChange={onChange}
          {...register}
          name={name}
          className={`pl-3 ${
            !value ? "text-gray-400" : "text-black"
          } focus:outline-none py-2  w-full border rounded-md focus:ring focus:ring-indigo-200 "
          } appearance-none`}
        >
          <option value="">{placeholder}</option>
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        <div className="absolute inset-y-0 right-0 top-[55%] -translate-y-[50%] pr-3 pointer-events-none">
          <SelectIcon className="h-4 w-4 text-gray-400" />
        </div>
      </div>
    </div>
  );
};

export default SelectInput;
