import React from "react";
import Logo from "../Common/Logo";
import { useDispatch, useSelector } from "react-redux";
import { toggleNav } from "../../features/NavigationSlice";
import { useNavigate } from "react-router-dom";
import UserAvatar from "../Common/UserAvatar";
import Button from "../Common/Button";
import { logout } from "../../features/UserAuthSlice";

const Navbar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const navOpenCheck = useSelector((state) => state.navigation.isNavOpen);
  const user = useSelector((state) => state?.userAuth?.user.userName);

  const handleToggleNav = () => dispatch(toggleNav());

  const handleLogOut = () => {
    dispatch(logout());
    navigate("/sign-in");
  };
  return (
    <nav className="py-4 px-5 bg-white fixed z-[50] w-full">
      <div className="flex items-center justify-between">
        <Logo />
        <div className="flex items-center gap-3 font-medium">
          <div
            onClick={handleToggleNav}
            className={`flex flex-col lg:hidden gap-1  cursor-pointer
            }`}
          >
            <div
              className={`h-[2px] bg-black transform transition-w duration-300  ${navOpenCheck ? "w-3" : "w-5"
                }`}
            ></div>
            <div className="w-5 h-[2px] bg-black"></div>
            <div
              className={`h-[2px] bg-black transition-w duration-300  ${navOpenCheck ? "w-2" : "w-5"
                }`}
              s
            ></div>
          </div>
          <div className="flex justify-center items-center gap-4">
            <div
              className="flex justify-center items-center gap-2"
              onClick={() => navigate("/system/user-profile")}
            >
              <div className="w-12 h-12 border-2  cursor-pointer bg-white rounded-full flex items-center justify-center">
                <UserAvatar size="43" name={user} />
              </div>
              <span className="hidden md:block">{user}</span>
            </div>
            <Button
              onClick={handleLogOut}
              type={"button"}
              buttonStyle={"bg-[#D20101] text-white"}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75"
                />
              </svg>
            </Button>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
