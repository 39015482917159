import React from "react";

const Button = ({ onClick, children, disable = false, buttonStyle, type }) => {
  return (
    <button
      type={type}
      onClick={onClick}
      disabled={disable}
      className={`px-4 flex text-sm md:text-base items-center gap-4  rounded-md py-1 ${buttonStyle} `}
    >
      {children}
    </button>
  );
};

export default Button;
