import React, { useState } from "react";
import ManageUsers from "./ManageUsers";
import Tabs from "../../component/Common/Tabs";
import AddProducts from "./AddProducts";
import Supplier from "./Suppliers";
import ProductsCategory from "./ProductsCategory";
import InventoryType from "./InventoryType";

import { useTranslation } from "react-i18next";

const Admin = () => {

  const { t } = useTranslation();

  const menuItems = [
    { label: `${t('All Users')}`, id: 1 },
    { label: `${t('Suppliers')}`, id: 2 },
    { label: `${t('Products Category')}`, id: 3 },
    { label: `${t('Add Products')}`, id: 4 },
    { label: `${t('Inventory Type')}`, id: 5 },
  ];

  const [active, setActive] = useState(1);



  return (
    <div className="min-h-[80vh]">
      <header className="">
        <ul className="flex text-gray-500 justify-center w-fit md:mx-auto border-b border-gray-300 gap-4">
          <Tabs setActive={setActive} active={active} menuItems={menuItems} />
        </ul>
      </header>
      <main className="">
        {active === 1 && <ManageUsers />}
        {active === 2 && <Supplier />}
        {active === 3 && <ProductsCategory />}
        {active === 4 && <AddProducts />}
        {active === 5 && <InventoryType />}
      </main>
    </div>
  );
};

export default Admin;
