import React, { useMemo } from "react";
import Table from "../Common/Table";
import { DeleteLogo, EditLogo } from "../../svgs/Index";
import { ModalService } from "../Common/Modal";
import { DeleteCategoryModal } from "../../component/Admin/AddProducts/CatModal/DeleteCatModal";
import CommonModal from "../Common/CommonModal";
import { updateCategory } from "../../features/Categories/CategoriesThunk";
import { useTranslation } from "react-i18next";



const ProductsCategoryTable = ({ categories }) => {
  const dataWithSequentialIds = useMemo(
    () =>
      categories.map((item, index) => ({
        ...item,
        id: index + 1,
      })),
    [categories]
  );

  const DeleteCatModal = (_id) => {
    const foundCategory = categories?.find((cat) => cat._id === _id);

    ModalService.open(DeleteCategoryModal, { foundCategory });
  };

  const openEditCategoryModal = (_id) => {
    const foundCategory = categories?.find((cat) => cat._id === _id);

    ModalService.open(CommonModal, {
      title: "Edit Category",
      inputName: "categoryName",
      inputDescription: "categoryDescription",
      action: updateCategory,
      type: "edit",
      foundData: foundCategory,
    });
  };

  const renderActions = (_id) => (
    <div className="inline-flex cursor-pointer items-center">
      <DeleteLogo onClick={() => DeleteCatModal(_id)} />
      <EditLogo onClick={() => openEditCategoryModal(_id)} className="ml-2" />
    </div>
  );

  const { t } = useTranslation();

  const columns = [
    {
      header: `${t('ID')}`,
      field: "id",
    },
    {
      header: `${t('Category Name')}`,
      field: "categoryName",
    },
    {
      header: `${t('Category Description')}`,
      field: "categoryDescription",
    },
  ];

  return (
    <>
      <Table
        data={dataWithSequentialIds}
        columns={columns}
        renderActions={renderActions}
      />
    </>
  );
};

export default ProductsCategoryTable;
