import { createSlice } from "@reduxjs/toolkit";
import {
  addInventoryType,
  deleteInventoryType,
  fetchInventoryTypes,
  updateInventoryType,
} from "./InventoryThunk";

const initialState = {
  inventoryTypes: [],
  loading: false,
  error: null,
};

// inventoryType slice
const InventoryTypeSlice = createSlice({
  name: "InventoryType",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchInventoryTypes.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchInventoryTypes.fulfilled, (state, action) => {
        state.loading = false;
        state.inventoryTypes = action.payload;
      })
      .addCase(fetchInventoryTypes.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(addInventoryType.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addInventoryType.fulfilled, (state, action) => {
        state.loading = false;
        state.inventoryTypes.push(action.payload);
      })
      .addCase(addInventoryType.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateInventoryType.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateInventoryType.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(updateInventoryType.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteInventoryType.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteInventoryType.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteInventoryType.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default InventoryTypeSlice.reducer;
