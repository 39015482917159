// Header.js
import React from "react";
import { NavLink } from "react-router-dom";
import "./Header.css";

function Header() {
    return (
        <header className="header">
            <div className="logo">
                {<img src="https://res.cloudinary.com/datnvhs9h/image/upload/v1696577193/gvinot_hagalil_logo_nud5sv.png" alt="Company Logo" />}
            </div>
            <nav className="navigation">
                <ul>
                    <li>
                        <NavLink exact to="/" activeClassName="active-link">
                            בית
                        </NavLink>
                    </li>
                    <li>
                        <NavLink exact to="/CatalogMain" activeClassName="active-link">
                            קטלוג מוצרים
                        </NavLink>
                    </li>
                </ul>
            </nav>
            { /* <nav className="navigation">
                <ul>
                    <li>בית</li>
                    <li>קטלוג מוצרים</li>
                    <li>הסיפור שלנו</li>
                    <li>יצירת קשר</li>
                </ul>
    </nav> 
            {/*<button className="cta-button">Shop Now</button>*/}
        </header>
    );
}

export default Header;
