import React, { useMemo } from "react";
import Table from "../Common/Table";
import { useSelector } from "react-redux";
import { EditLogo, DeleteLogo } from "../../svgs/Index";
import { ModalService } from "../Common/Modal";
import CommonModal from "../Common/CommonModal";
import { DeleteInventryTypeModal } from "./InventoryTypeModal/DeleteInventoryTypeModal";
import { updateInventoryType } from "../../features/InventoryType/InventoryThunk";
import { useTranslation } from "react-i18next";

const InventoryTypeTable = () => {
  const inventoryTypesData = useSelector(
    (state) => state.inventoryTypes.inventoryTypes
  );

  console.count("renders");

  const dataWithSequentialIds = useMemo(
    () =>
      inventoryTypesData.map((item, index) => ({
        ...item,
        id: index + 1,
      })),
    [inventoryTypesData]
  );

  const handleInventoryTypeDeleteModal = (_id) => {
    const foundInventoryType = inventoryTypesData?.find(
      (cat) => cat._id === _id
    );
    ModalService.open(DeleteInventryTypeModal, { foundInventoryType });
  };

  const handleOpenEditInventoryModal = (_id) => {
    const foundInventoryType = inventoryTypesData?.find(
      (cat) => cat._id === _id
    );

    ModalService.open(CommonModal, {
      title: "Edit InventoryType",
      foundData: foundInventoryType,
      type: "edit",
      inputName: "inventoryTypeName",
      inputDescription: "inventoryTypeDescription",
      action: updateInventoryType,
    });
  };
  const renderActions = (id) => (
    <div className="inline-flex cursor-pointer items-center">
      <DeleteLogo onClick={() => handleInventoryTypeDeleteModal(id)} />
      <EditLogo
        onClick={() => handleOpenEditInventoryModal(id)}
        className="ml-2"
      />
    </div>
  );

  const { t } = useTranslation();

  const columns = [
    {
      header: `${t('ID')}`,
      field: "id",
    },
    {
      header: `${t('Name')}`,
      field: "inventoryTypeName",
    },
    {
      header: `${t('Description')}`,
      field: "inventoryTypeDescription",
    },
  ];

  return (
    <Table
      columns={columns}
      data={dataWithSequentialIds}
      renderActions={renderActions}
    />
  );
};

export default InventoryTypeTable;
