import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import TransactionTable from "../../component/Report/TransactionTable";
import StandardHeader from "../../component/Common/StandardHeader";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { fetchTransactions } from "../../features/Transaction/transactionThunk";
import { useTranslation } from "react-i18next";

const Transaction = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchTransactions());
  }, []);

  const { t } = useTranslation();
  return (
    <div className="mt-6 min-h-[80vh] bg-white rounded-md shadow-md p-4">
      <StandardHeader label={`${t('Transaction History')}`} />
      <TransactionTable />
    </div>
  );
};

export default Transaction;
