import React, { useEffect, useState } from "react";
import { SelectIcon, SelectUpIcon } from "../../svgs/Index";
import { ProductImageUpload } from "../../component/Admin/AddProducts/ProductImageUpload";
import SelectInput from "../../component/Common/SelectInput";
import Button from "../../component/Common/Button";
import axios from "axios";
import StandardHeader from "../../component/Common/StandardHeader";
import { useSelector } from "react-redux";
import { fetchAllData } from "../../features/productData";
import { useDispatch } from "react-redux";
import { addProduct } from "../../features/product/productThunk";
import { useForm } from "react-hook-form";

import { useTranslation } from "react-i18next";


const AddProducts = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [quantity, setQuantity] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const categories = useSelector((state) => state.categories.categories);
  const inventoryTypes = useSelector(
    (state) => state.inventoryTypes.inventoryTypes
  );

  const { register, handleSubmit, setValue, reset, watch } = useForm();

  const suppliers = useSelector((state) => state.suppliers.suppliers);
  useEffect(() => {
    dispatch(fetchAllData());
  }, [dispatch]);

  const resetAll = () => {
    reset();
    setImagePreview(null);
    setQuantity(null);
  };

  const handleIncrement = () => {
    const updatedQuantity = Math.max(0, quantity + 1);
    setQuantity(updatedQuantity);
    setValue("quantity", updatedQuantity);
  };

  const handleDecrement = () => {
    const updatedQuantity = Math.max(0, quantity - 1);
    setQuantity(updatedQuantity);
    setValue("quantity", updatedQuantity);
  };

  const handleSave = async (formData) => {
    if (!formData.image) {
      return;
    }
    setLoading(true);
    console.log(formData);
    try {
      const formDataImage = new FormData();
      formDataImage.append("image", formData.image);
      const { data } = await axios.post("api/upload", formDataImage);
      if (!data.url) {
        throw new Error("Image upload failed");
      }

      const imageUrl = data.url;
      formData.image = imageUrl;

      dispatch(addProduct(formData));
      setLoading(false);
      setImagePreview(null);
      reset();
    } catch (error) {
      console.error("Error:", error.message);
      setLoading(false);
    }
  };

  const { t } = useTranslation();

  return (
    <div className="mt-6 bg-white rounded-md min-h-[80vh] shadow-md p-4">
      <StandardHeader label={t('Add new Product')} />
      <div className="border  p-2 mt-6 ">
        <form
          onSubmit={handleSubmit(handleSave)}
          className="flex justify-between md:flex-row flex-col gap-4"
        >
          <div className="md:w-1/3">
            <ProductImageUpload
              setValue={setValue}
              imagePreview={imagePreview}
              setImagePreview={setImagePreview}
            />
            <div className="flex mt-2 mb-2 flex-col gap-1">
              <label className="block text-sm font-medium text-gray-700">
                {t('Quantity Order Level')}
              </label>
              <input
                name="quantityOrderLevelStock"
                type="text"
                {...register("quantityOrderLevelStock", {
                  required: "QuantityOrderLevelStock is required",
                })}
                className="mt-1 px-3 py-2 w-full focus:outline-none border rounded-md focus:ring focus:ring-indigo-200"
                placeholder={t('Enter the Quantity Order level')}
              />
            </div>
            <div className="flex flex-col gap-1">
              <label className="block text-sm font-medium text-gray-700">
                {t('Consumption')}
              </label>
              <input
                name="consumption"
                type="text"
                {...register("consumption", {
                  required: "Consumption is required",
                })}
                className="mt-1 px-3 py-2 w-full focus:outline-none border rounded-md focus:ring focus:ring-indigo-200"
                placeholder={t('Enter the Consumption Rate')}
              />
            </div>
          </div>
          <div className="md:w-1/3 flex flex-col gap-2">
            <div className="flex flex-col gap-1">
              <label className="block text-sm font-medium text-gray-700">
                {t('Product Name')}
              </label>
              <input
                name="productName"
                type="text"
                {...register("productName", {
                  required: "productName is required",
                })}
                className="mt-1 px-3 py-2 w-full focus:outline-none border rounded-md focus:ring focus:ring-indigo-200"
                placeholder={t('Enter the product name')}
              />
            </div>
            <SelectInput
              name="supplierId"
              register={register("supplierId", {
                required: "supplierId is required",
              })}
              value={watch("supplierId")}
              placeholder={`${t('Enter supplier name')}`}
              label={`${t('Supplier name')}`}
              options={suppliers.map((supplier) => ({
                label: supplier.supplierName,
                value: supplier._id,
              }))}
            />
            <div className=" flex flex-col gap-1">
              <label className="block text-sm font-medium text-gray-700">
                {t('Product Quantity')}
              </label>
              <div className="relative">
                <input
                  {...register("quantity", {
                    required: "Quantity is required",
                  })}
                  inputMode="numeric"
                  type="number"
                  className="mt-1 appearance-none px-3 py-2 w-full focus:outline-none border rounded-md focus:ring focus:ring-indigo-200 pr-12"
                  placeholder={t('Enter the Quantity')}
                />
                <Button
                  onClick={handleDecrement}
                  type="button"
                  buttonStyle="absolute right-0 rotate-180 bottom-[33%] transform translate-y-1/2"
                >
                  <SelectUpIcon className="h-3 w-3 text-gray-400" />
                </Button>
                <Button
                  onClick={handleIncrement}
                  type="button"
                  buttonStyle="absolute right-0 rotate-180 top-[40%] transform -translate-y-1/2"
                >
                  <SelectIcon className="h-3 w-3 text-gray-400" />
                </Button>
              </div>
            </div>

            <div className="flex flex-col gap-1">
              <label className="block text-sm font-medium text-gray-700">
                {t('Delivery Time')}
              </label>
              <input
                name="deliveryTime"
                type="text"
                {...register("deliveryTime", {
                  required: "Delivery Time is Required",
                })}
                className="mt-1 px-3 py-2 w-full focus:outline-none border rounded-md focus:ring focus:ring-indigo-200"
                placeholder={t('Enter the Delivery Time')}
              />
            </div>
            <div className="mb-4 flex flex-col gap-1">
              <label className="block text-sm font-medium text-gray-700">
                {t('Product Description')}
              </label>
              <textarea
                {...register("productDescription", {
                  required: "Product Description is required",
                })}
                name="productDescription"
                className="mt-1 px-3 py-2 w-full resize-none focus:outline-none border rounded-md focus:ring focus:ring-indigo-200"
                placeholder={t('Enter the product description')}
                rows={4}
              />
            </div>
          </div>
          <div className="md:w-1/3 flex flex-col gap-2">
            <SelectInput
              value={watch("categoryId")}
              name="categoryId"
              register={register("categoryId", {
                required: "categoryId is required",
              })}
              placeholder={`${t('Enter product category')}`}
              label={`${t('Product Category')}`}
              options={categories.map((category) => ({
                label: category.categoryName,
                value: category._id,
              }))}
            />
            <SelectInput
              value={watch("inventoryTypeId")}
              name="inventoryTypeId"
              placeholder={`${t('Select inventory type')}`}
              register={register("inventoryTypeId", {
                required: "inventoryTypeId is required",
              })}
              label={`${t('Inventory Type')}`}
              options={inventoryTypes.map((type) => ({
                label: type.inventoryTypeName,
                value: type._id,
              }))}
            />
            <div className="flex flex-col gap-1">
              <label className="block text-sm font-medium text-gray-700">
                {t('Barcode Number')}
              </label>
              <input
                {...register("barcode", {
                  required: "barcode is required",
                })}
                name="barcode"
                type="number"
                className="mt-1 px-3 py-2 w-full focus:outline-none border rounded-md focus:ring focus:ring-indigo-200"
                placeholder={`${t('Enter the Barcode Number')}`}
              />
            </div>
            <div className=" flex flex-col gap-1">
              <label className="block text-sm font-medium text-gray-700">
                {t('Product ID')}
              </label>
              <input
                {...register("productId", {
                  required: "productId is required",
                })}
                name="productId"
                type="text"
                className="mt-1 px-3 py-2 w-full focus:outline-none border rounded-md focus:ring focus:ring-indigo-200"
                placeholder={`${t('Enter the Product ID')}`}
              />
            </div>
            <div className="mb-4 flex flex-col gap-1">
              <label className="block text-sm font-medium text-gray-700">
                {t('P. Level')}
              </label>
              <input
                {...register("productLevel", {
                  required: "productLevel is required",
                })}
                name="productLevel"
                type="text"
                className="mt-1 px-3 py-2 w-full focus:outline-none border rounded-md focus:ring focus:ring-indigo-200"
                placeholder={`${t('Enter the Product Level')}`}
              />
            </div>

            <div className="flex gap-2 items-end self-end mt-2 md:mt-0 md:-translate-y-5 flex-grow">
              <Button
                onClick={resetAll}
                type="button"
                buttonStyle="text-gray-700 bg-gray-200 "
              >
                {t('Reset')}
              </Button>
              <Button
                disabled={loading}
                buttonStyle="mr-2 text-white bg-primary "
              >
                {t('Save')}
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddProducts;
