import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchProducts = createAsyncThunk(
  "product/fetchProducts",
  async () => {
    try {
      const response = await axios.get("/api/products");
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const addProduct = createAsyncThunk(
  "product/addProduct",
  async (productData) => {
    try {
      const response = await axios.post("/api/products", productData);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const updateProduct = createAsyncThunk(
  "product/updateProduct",
  async (productData, { dispatch }) => {
    try {
      await axios.put(
        `/api/products/${productData.id}`,
        productData?.updatedFields
      );
      dispatch(fetchProducts());
      return;
    } catch (err) {
      throw err;
    }
  }
);

export const deleteProduct = createAsyncThunk(
  "product/deleteProduct",
  async ({ id }, { dispatch }) => {
    try {
      await axios.delete(`/api/products/${id}`);
      dispatch(fetchProducts());
      return;
    } catch (err) {
      throw err;
    }
  }
);
