import { Sidebar, Menu } from "react-pro-sidebar";
import SidebarMenuItem from "./SidebarMenuItem";
import { AdminIcon, ReportIcon, InventoryIcon } from "../../svgs/Index";
import { useDispatch } from "react-redux";
import { closeNav } from "../../features/NavigationSlice";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const SidebarMenu = () => {
  const userType = useSelector((state) => state.userAuth?.user?.userRole);

  const { t } = useTranslation();
  return (
    <Sidebar backgroundColor="white" style={{ height: "100vh" }}>
      <Menu
        className="px-4 text-left"
        menuItemStyles={{
          button: {
            backgroundColor: "white",
            "&:hover": {
              backgroundColor: "transparent",
            },
          },
        }}
      >
        {userType === "admin" ? (
          <>
            <SidebarMenuItem
              icon={({ isActive }) => <AdminIcon isActive={isActive} />}
              to="Admin"
              text={t('Admin')}
            />
            <SidebarMenuItem
              icon={({ isActive }) => <ReportIcon isActive={isActive} />}
              to={"report"}
              text={t('Reports')}
            />
            <SidebarMenuItem
              icon={({ isActive }) => <InventoryIcon isActive={isActive} />}
              to={"inventory"}
              text={t('Inventory')}
            />
          </>
        ) : userType === "manager" ? (
          <>
            <SidebarMenuItem
              icon={({ isActive }) => <ReportIcon isActive={isActive} />}
              to={"report"}
              text={t('Reports')}
            />
            <SidebarMenuItem
              icon={({ isActive }) => <InventoryIcon isActive={isActive} />}
              to={"inventory"}
              text={t('Inventory')}
            />
          </>
        ) : (
          <SidebarMenuItem
            icon={({ isActive }) => <InventoryIcon isActive={isActive} />}
            to={"inventory"}
            text={t('Inventory')}
          />
        )}
      </Menu>
    </Sidebar>
  );
};


const MobileSidebar = () => {
  const { t } = useTranslation();
  const isOpen = useSelector((state) => state.navigation.isNavOpen);
  const dispatch = useDispatch();
  const userType = useSelector((state) => state?.userAuth?.user?.userRole);
  const handleCloseNav = () => dispatch(closeNav());
  return (
    <Sidebar
      backgroundColor="white"
      className="lg:hidden h-[80vh] md:h-[85vh] top-24 shadow-md rounded-md"
      style={{
        position: "fixed",
        left: isOpen ? 0 : "-100%",
        transition: "left 0.3s ease-in-out",
        width: "50%",

        zIndex: 2000,
      }}
    >
      <Menu
        className="px-4 py-4 text-left"
        menuItemStyles={{
          button: {
            backgroundColor: "white",
            "&:hover": {
              backgroundColor: "transparent",
            },
          },
        }}
      >
        {userType === "admin" ? (
          <>
            <SidebarMenuItem
              icon={({ isActive }) => <AdminIcon isActive={isActive} />}
              to="Admin"
              text={t('Admin')}
              onClick={handleCloseNav}
            />
            <SidebarMenuItem
              icon={({ isActive }) => <ReportIcon isActive={isActive} />}
              to={"report"}
              text={t('Reports')}
              onClick={handleCloseNav}
            />
            <SidebarMenuItem
              icon={({ isActive }) => <InventoryIcon isActive={isActive} />}
              to={"inventory"}
              text={t('Inventory')}
              onClick={handleCloseNav}
            />
          </>
        ) : userType === "manager" ? (
          <>
            <SidebarMenuItem
              icon={({ isActive }) => <ReportIcon isActive={isActive} />}
              to={"report"}
              text={t('Reports')}
              onClick={handleCloseNav}
            />
            <SidebarMenuItem
              icon={({ isActive }) => <InventoryIcon isActive={isActive} />}
              to={"inventory"}
              text={t('Inventory')}
              onClick={handleCloseNav}
            />
          </>
        ) : (
          <SidebarMenuItem
            icon={({ isActive }) => <InventoryIcon isActive={isActive} />}
            to={"inventory"}
            text={t('Inventory')}
            onClick={handleCloseNav}
          />
        )}
      </Menu>
    </Sidebar>
  );
};

export { SidebarMenu, MobileSidebar };
