import React, { useMemo } from "react";
import Table from "../Common/Table";
import { DeleteLogo, EditLogo } from "../../svgs/Index";
import { ModalService } from "../Common/Modal";
import { DeleteSupplierModal } from "./Supplier/DeleteSupplierModal";
import CommonModal from "../Common/CommonModal";
import { updateSupplier } from "../../features/Supplier/SupplierThunk";

import { useTranslation } from "react-i18next";

const columns = [
  {
    header: "ID",
    field: "id",
  },
  {
    header: "Supplier Name",
    field: "supplierName",
  },
  {
    header: "Supplier Description",
    field: "supplierDescription",
  },
];

const SupplierTable = ({ supplierData }) => {
  const dataWithSequentialIds = useMemo(
    () =>
      supplierData.map((item, index) => ({
        ...item,
        id: index + 1,
      })),
    [supplierData]
  );

  const handleOpenSupplierDeleteModal = (_id) => {
    const foundSupplier = supplierData?.find((cat) => cat._id === _id);

    ModalService.open(DeleteSupplierModal, { foundSupplier });
  };

  const handleOpenSupplierModel = (_id) => {
    const foundSupplier = supplierData?.find((cat) => cat._id === _id);
    ModalService.open(CommonModal, {
      title: "Edit Supplier",
      action: updateSupplier,
      inputName: "supplierName",
      inputDescription: "supplierDescription",
      foundData: foundSupplier,
      type: "edit",
    });
  };
  const renderActions = (id) => (
    <div className="inline-flex cursor-pointer items-center">
      <DeleteLogo onClick={() => handleOpenSupplierDeleteModal(id)} />
      <EditLogo className="ml-2" onClick={() => handleOpenSupplierModel(id)} />
    </div>
  );

  const { t } = useTranslation();

  return (
    <div>
      <Table
        columns={[
          {
            header: `${t('Supplier ID')}`,
            field: "id",
          },
          {
            // header: "Supplier Name",
            header: `${t('Supplier name')}`,
            field: "supplierName",
          },
          {
            header: `${t('Supplier Description')}`,
            field: "supplierDescription",
          }
        ]
        }
        data={dataWithSequentialIds}
        renderActions={renderActions}
      />
    </div >
  );
};

export default SupplierTable;
