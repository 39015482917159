import React, { useEffect } from "react";

import { SidebarMenu, MobileSidebar } from "../component/Sidebar/Index";
import Navbar from "../component/Navbar/Index";

import { Outlet } from "react-router-dom";

const Home = () => {
  return (
    <div className="">
      <Navbar />
      <div className="flex relative">
        <div className="hidden lg:block fixed top-24 left-0 h-full">
          <SidebarMenu />
        </div>
        <MobileSidebar />

        <main className="flex-grow overflow-x-scroll min-h-screen rounded p-4 bg-[#E9F2FA] lg:ml-64">
          {" "}
          <div className="mt-20">
            {" "}
            <Outlet />
          </div>
        </main>
      </div>
    </div>
  );
};

export default Home;
