import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchCategories = createAsyncThunk(
  "categories/fetchCategories",
  async () => {
    try {
      const response = await axios.get("/api/categories");
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

// Add a new category
export const addCategory = createAsyncThunk(
  "categories/addCategory",
  async (categoryData) => {
    try {
      const response = await axios.post("/api/categories", categoryData);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

//edit a category
export const updateCategory = createAsyncThunk(
  "categories/updateCategory",
  async (updatedData, { dispatch }) => {
    try {
      const response = await axios.put(
        `/api/categories/${updatedData.id}`,
        updatedData
      );
      dispatch(fetchCategories());
      return response.data;
    } catch (err) {
      throw err;
    }
  }
);

//delete a category
export const deleteCategory = createAsyncThunk(
  "categories/deleteCategory",
  async ({ id }, { dispatch }) => {
    try {
      const response = await axios.delete(`/api/categories/${id}`);
      dispatch(fetchCategories());
      return response.data;
    } catch (err) {
      throw err;
    }
  }
);
