"use client";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { deleteInventoryType } from "../../../features/InventoryType/InventoryThunk";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "../../Common/Modal";
import Button from "../../Common/Button";

export const DeleteInventryTypeModal = ({ close, foundInventoryType = "" }) => {
  const dispatch = useDispatch();

  const handleSave = async (e) => {
    e.preventDefault();
    try {
      dispatch(deleteInventoryType({ id: foundInventoryType._id }));
      close();
    } catch (error) {
      console.error("Error adding category:", error);
    }
  };

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (e.target.classList.contains("modal-overlay")) {
        close();
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [close]);

  return (
    <Modal>
      <form onSubmit={handleSave}>
        <ModalHeader>Delete Inventory Type</ModalHeader>
        <ModalBody>
          <div className="mb-4 flex justify-center py-2">
            <p>
              Are you sure you want to delete{" "}
              <span className="font-semibold">
                {foundInventoryType?.inventoryTypeName}
              </span>{" "}
              Inventory Type?
            </p>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="flex justify-end gap-2">
            <Button buttonStyle={"text-gray-700 bg-gray-200"} onClick={close}>
              Cancel
            </Button>
            <Button buttonStyle={"mr-2 text-white bg-[#D20101]"} type="submit">
              Delete
            </Button>
          </div>
        </ModalFooter>
      </form>
    </Modal>
  );
};
