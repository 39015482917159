import React from "react";

import { useTranslation } from "react-i18next";


const Table = ({ columns, data, renderActions }) => {

  const { t } = useTranslation();

  return (
    <div className="border overflow-auto p-4 w-full mt-6">
      <table className="text-center table-auto lg:table-fixed w-full">
        <thead className="text-sm whitespace-nowrap w-full border-b">
          <tr>
            {columns?.map((column, index) => (
              <th
                key={index}
                scope="col"
                className="py-3 px-3 lg:px-0 font-bold"
              >
                {column.header}
                <br />
                <span className="text-[0.60rem] font-medium">
                  {column.subheader}
                </span>
              </th>
            ))}
            {renderActions && (
              <th scope="col" className="py-3 px-3 lg:px-0 font-bold">
                {t('Action')}
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          {data?.map((row, rowIndex) => (
            <tr
              key={rowIndex}
              className="bg-white border-b whitespace-nowrap hover:bg-gray-50"
            >
              {columns.map((column, columnIndex) => (
                <td key={columnIndex} className=" px-3 lg:px-0">
                  {column.field === "quantity" ? (
                    <div
                      className={`
                      ${row.quantity < row.consumption * row.deliveryTime
                          ? "bg-red-500 py-2 text-white"
                          : row.quantity >= row.consumption * row.deliveryTime
                            ? "bg-green-500 py-2 text-white"
                            : ""
                        }
                    `}
                    >
                      {row.quantity}
                    </div>
                  ) : column.field === "profile" ? (
                    <div className="flex justify-center">
                      <img
                        className="w-10 h-10 rounded-full object-cover"
                        src={row.profileImage}
                        alt={row.name}
                      />
                    </div>
                  ) : column.field === "createdAt" ? (
                    <div>{new Date(row?.createdAt).toLocaleDateString()}</div>
                  ) : column.field === "quantity" ? (
                    <div>{Math.abs(row.quantity)}</div>
                  ) : (
                    <div
                      className={`flex justify-center py-2 ${row[column.field] === "pull" &&
                        "bg-red-600 font-semibold text-white "
                        } ${row[column.field] === "push" &&
                        "bg-green-500 font-semibold text-white"
                        }`}
                    >
                      {row[column.field]}
                    </div>
                  )}
                </td>
              ))}
              {renderActions && (
                <td className="py-4 px-3 lg:px-0">
                  <div className="flex justify-center">
                    {renderActions(row._id)}
                  </div>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
