// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Header.css */
.header {
    background-color: #0074d9;
    color: #fff;
    padding: 1px;
    text-align: center;
    display: flex;
    /* Center the header content */

}

.logo {
    display: flex;
    align-items: right;
    justify-content: right;
    /* Center the logo horizontally */
}

.logo img {
    width: 100px;
    height: 90px;
    margin-right: 50px;
}

.navigation ul {
    list-style-type: none;
    padding-top: 40px;
    padding-right: 120px;
    display: flex;
    justify-content: center;
    font-size: larger;
    /* Center the navigation horizontally */
}

.navigation li {
    margin-right: 40px;
}

.cta-button {
    background-color: #ff4136;
    color: #fff;
    padding: 10px 30px;
    border: none;
    cursor: pointer;
    font-weight: bold;
}

.cta-button:hover {
    background-color: #d90000;
}`, "",{"version":3,"sources":["webpack://./src/pages/Catalog/Header.css"],"names":[],"mappings":"AAAA,eAAe;AACf;IACI,yBAAyB;IACzB,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,aAAa;IACb,8BAA8B;;AAElC;;AAEA;IACI,aAAa;IACb,kBAAkB;IAClB,sBAAsB;IACtB,iCAAiC;AACrC;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,qBAAqB;IACrB,iBAAiB;IACjB,oBAAoB;IACpB,aAAa;IACb,uBAAuB;IACvB,iBAAiB;IACjB,uCAAuC;AAC3C;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;IACzB,WAAW;IACX,kBAAkB;IAClB,YAAY;IACZ,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":["/* Header.css */\n.header {\n    background-color: #0074d9;\n    color: #fff;\n    padding: 1px;\n    text-align: center;\n    display: flex;\n    /* Center the header content */\n\n}\n\n.logo {\n    display: flex;\n    align-items: right;\n    justify-content: right;\n    /* Center the logo horizontally */\n}\n\n.logo img {\n    width: 100px;\n    height: 90px;\n    margin-right: 50px;\n}\n\n.navigation ul {\n    list-style-type: none;\n    padding-top: 40px;\n    padding-right: 120px;\n    display: flex;\n    justify-content: center;\n    font-size: larger;\n    /* Center the navigation horizontally */\n}\n\n.navigation li {\n    margin-right: 40px;\n}\n\n.cta-button {\n    background-color: #ff4136;\n    color: #fff;\n    padding: 10px 30px;\n    border: none;\n    cursor: pointer;\n    font-weight: bold;\n}\n\n.cta-button:hover {\n    background-color: #d90000;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
