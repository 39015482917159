import React from "react";
import Table from "../Common/Table";
import { fetchProducts } from "../../features/product/productThunk";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const QuantityTable = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchProducts());
  }, [dispatch]);

  const products = useSelector((state) => state.products.products);
  console.log(products);

  const { t } = useTranslation();

  const columns = [
    {
      header: `${t('Product Name')}`,
      subheader: `${t('(Short Product Name)')}`,
      field: "productName",
    },
    {
      header: `${t('Date')}`,
      subheader: `${t('(Initiated date)')}`,
      field: "createdAt",
    },
    {
      header: `${t('Quantity Stock')}`,
      // subheader: "(Quantity Into the Stock)",
      field: "quantity",
      className: (item) =>
        `${item.quantityStock < item.consumption * item.deliveryTime
          ? "bg-red-500 py-2 text-white"
          : item.quantityStock >= item.consumption * item.deliveryTime
            ? "bg-green-500 py-2 text-white"
            : ""
        }`,
    },
    {
      header: `${t('Quantity Order Level')}`,
      subheader: `${t('(Quantity Order Level Stock)')}`,
      field: "quantityOrderLevelStock",
    },
    {
      header: `${t('Consumption')}`,
      subheader: `${t('(Weekly)')}`,
      field: "consumption",
    },
    {
      header: `${t('Delivery Time')}`,
      subheader: `${t('(Delivery Time in weeks)')}`,
      field: "deliveryTime",
    },
    {
      header: `${t('P. Level')}`,
      // subheader: "(Product Level)",
      field: "productLevel",
    },
  ];

  return <Table columns={columns} data={products} />;
};

export default QuantityTable;
