import React from "react";

import "react-datepicker/dist/react-datepicker.css";

import QuantityTable from "../../component/Report/QuantityTable";

import StandardHeader from "../../component/Common/StandardHeader";

import { useTranslation } from "react-i18next";

const QuantityReport = () => {
  const { t } = useTranslation();

  return (
    <div className="mt-6 min-h-[80vh] bg-white rounded-md shadow-md p-4">
      <StandardHeader label={`${t('Quantity Management')}`} />
      <div className="">
        <QuantityTable />
      </div>
    </div>
  );
};

export default QuantityReport;
