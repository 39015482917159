import React, { useState, useEffect } from "react";
import InventoryProducts from "../../component/Inventory/InventoryProducts";
import SelectInput from "../../component/Common/SelectInput";
import StandardHeader from "../../component/Common/StandardHeader";
import { useDispatch, useSelector } from "react-redux";
import { fetchProducts } from "../../features/product/productThunk";
import { fetchCategories } from "../../features/Categories/CategoriesThunk";
import { useTranslation } from "react-i18next";

const Inventory = () => {
  const dispatch = useDispatch();
  const products = useSelector((state) => state.products.products);
  const categories = useSelector((state) => state.categories.categories);
  const [categoryFilter, setCategoryFilter] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    dispatch(fetchProducts());
    dispatch(fetchCategories());
  }, []);

  const handleCategoryFilterChange = (selectedCategory) => {
    setCategoryFilter(selectedCategory);
  };

  const handleSearchQueryChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredProducts = products.filter((product) => {
    const categoryMatches =
      categoryFilter === "" || product.categoryId?._id === categoryFilter;

    const queryMatches =
      product.productName?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
      product.productDescription?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      product.barcode?.toLowerCase().includes(searchQuery?.toLowerCase());

    return categoryMatches && queryMatches;
  });

  const { t } = useTranslation();

  return (
    <div className="mt-6 min-h-[80vh] bg-white rounded-md shadow-md p-4">
      <StandardHeader label={`${t('Inventory')}`}>
        <div className="w-56">
          <SelectInput
            placeholder={`${t('Filter by Category')}`}
            name="filterby"
            value={categoryFilter}
            onChange={(e) => handleCategoryFilterChange(e.target.value)}
            options={categories.map((category) => ({
              label: category?.categoryName,
              value: category._id,
            }))}
          />
        </div>
      </StandardHeader>
      <div>
        <input
          type="text"
          id="searchQuery"
          className="block w-fit p-2 border rounded-md mt-4 focus:ring focus:ring-indigo-200 focus:outline-none"
          value={searchQuery}
          onChange={handleSearchQueryChange}
          placeholder={" ..." + `${t('Search')}`}
        />
      </div>
      <InventoryProducts products={filteredProducts} />
    </div>
  );
};

export default Inventory;
