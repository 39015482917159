import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import Languagedetector from 'i18next-browser-languagedetector'

import engTranslation from './locales/eng.json';
import hebTranslation from './locales/heb.json';


i18next
    .use(initReactI18next)
    .use(Languagedetector)
    .init({
        lng: 'heb', // Default language
        resources: {
            en: {
                translation: engTranslation
            },
            heb: {
                translation: hebTranslation
            },
        },
    });