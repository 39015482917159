import React, { useRef, useState } from "react";
import { UploadIcon } from "../../../svgs/Index";

import { useTranslation } from 'react-i18next'

export const ProductImageUpload = ({
  imagePreview,
  setImagePreview,
  setValue,
  type,
}) => {
  const inputRef = useRef(null);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setValue("image", file);
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const setProductImage = () => {
    inputRef.current.click();
  };

  const { t } = useTranslation();

  return (
    <div
      onClick={setProductImage}
      className={` ${type === "edit" ? "md:w-full lg:w-1/3" : ""
        } h-56  py-4 cursor-pointer border-dashed flex items-center justify-center border-spacing-7 border-2`}
    >
      {imagePreview ? (
        <img
          src={imagePreview}
          alt="Uploaded Product"
          className="w-full h-full object-contain"
        />
      ) : (
        <div className="flex text-gray-400 items-center text-center flex-col justify-center gap-2">
          <UploadIcon />
          {t('uploadPic')}
        </div>
      )}
      <input
        hidden
        ref={inputRef}
        type="file"
        accept="image/*"
        onChange={handleImageChange}
      />
    </div>
  );
};
