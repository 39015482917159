import React, { useState } from "react";
import Button from "../../component/Common/Button";
import ProfileInput from "../../component/UserProfile/ProfileInput";
import StandardHeader from "../../component/Common/StandardHeader";
import { ModalRoot } from "../../component/Common/Modal";
import UserAvatar from "../../component/Common/UserAvatar";
import { useSelector } from "react-redux";

const Profile = () => {
  const user = useSelector((state) => state?.userAuth?.user);

  const [formData, setFormData] = useState({
    userName: user?.userName,
    email: user?.email,
  });
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const updateFormData = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  return (
    <div className="mt-6 bg-white min-h-[80vh] rounded-md shadow-md p-4">
      <StandardHeader label={"Profile"} />
      <div className="border p-4 mt-4 flex flex-col gap-3 py-8 rounded-md items-center justify-center">
        <UserAvatar name={user.userName} />
        <div className="flex flex-col gap-1 md:w-3/4  items-center w-full">
          <ProfileInput
            label="Username"
            name="userName"
            type="text"
            field={"userName"}
            username={formData.userName}
            updateFormData={updateFormData}
            onChange={handleChange}
            formData={formData}
          />

          <ProfileInput
            formData={formData}
            updateFormData={updateFormData}
            label="Email"
            field={"email"}
            name="email"
            type="text"
            onChange={handleChange}
          />
        </div>
      </div>
      <ModalRoot />
    </div>
  );
};

export default Profile;
