"use client";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { deleteCategory } from "../../../../features/Categories/CategoriesThunk";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "../../../Common/Modal";
import Button from "../../../Common/Button";

export const DeleteCategoryModal = ({ close, foundCategory = "" }) => {
  const dispatch = useDispatch();

  const handleSave = async (e) => {
    e.preventDefault();
    try {
      dispatch(deleteCategory({ id: foundCategory._id }));
      close();
    } catch (error) {
      console.error("Error adding category:", error);
    }
  };

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (e.target.classList.contains("modal-overlay")) {
        close();
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [close]);

  return (
    <Modal>
      <form onSubmit={handleSave}>
        <ModalHeader>Delete Category</ModalHeader>
        <ModalBody>
          <div className="mb-4 flex justify-center py-2">
            <p>
              Are you sure you want to delete{" "}
              <span className="font-semibold">
                {foundCategory.categoryName}
              </span>{" "}
              Category ?
            </p>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="flex justify-end gap-2">
            <Button buttonStyle={"text-gray-700 bg-gray-200"} onClick={close}>
              Cancel
            </Button>
            <Button buttonStyle={"mr-2 text-white bg-[#D20101]"} type="submit">
              Delete
            </Button>
          </div>
        </ModalFooter>
      </form>
    </Modal>
  );
};
