import React, { useEffect, useState } from "react";
import { SelectIcon, SelectUpIcon } from "../../svgs/Index";
import { ProductImageUpload } from "../Admin/AddProducts/ProductImageUpload";
import SelectInput from "../Common/SelectInput";
import Button from "../Common/Button";
import { useSelector } from "react-redux";
import { fetchAllData } from "../../features/productData";
import { useDispatch } from "react-redux";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "../Inventory/modal";
import { useForm } from "react-hook-form";
import { updateProduct } from "../../features/product/productThunk";
import axios from "axios";

import { useTranslation } from "react-i18next";

export const ProductEditModal = ({ close, product }) => {
  const dispatch = useDispatch();
  const [quantity, setQuantity] = useState(null);
  const [imagePreview, setImagePreview] = useState(product?.image);
  const categories = useSelector((state) => state.categories.categories);
  const user = useSelector((state) => state?.userAuth?.user);

  const inventoryTypes = useSelector(
    (state) => state.inventoryTypes.inventoryTypes
  );
  const newProduct = product && {
    ...product,
    supplierId: product?.supplierId?._id,
    categoryId: product?.categoryId?._id,
    inventoryTypeId: product?.inventoryTypeId?._id,
  };
  const { register, handleSubmit, setValue, reset, watch } = useForm({
    defaultValues: newProduct,
  });

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (e.target.classList.contains("modal-overlay")) {
        close();
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [close]);
  const suppliers = useSelector((state) => state.suppliers.suppliers);
  useEffect(() => {
    dispatch(fetchAllData());
  }, []);

  const handleIncrement = () => {
    const currentQuantity = watch("quantity");
    const updatedQuantity = currentQuantity + 1;
    setValue("quantity", updatedQuantity);
  };

  const handleDecrement = () => {
    const currentQuantity = watch("quantity");
    const updatedQuantity = Math.max(0, currentQuantity - 1);
    setValue("quantity", updatedQuantity);
  };

  const handleSave = async (formData) => {
    try {
      const updatedFields = {};
      let updatedImage = null;

      for (const key in formData) {
        if (key === "image" && formData[key] !== product.image) {
          updatedImage = formData[key];
        } else if (formData[key] !== product[key]) {
          updatedFields[key] = formData[key];
        }
      }

      if (Object.keys(updatedFields).length === 0 && !updatedImage) {
        close();
        return;
      }

      if (updatedImage) {
        const formDataImage = new FormData();
        formDataImage.append("image", updatedImage);
        const { data } = await axios.post("/upload", formDataImage);
        if (data.url) {
          updatedFields.image = data.url;
        } else {
          throw new Error("Image upload failed");
        }
      }
      updatedFields.userId = user?._id;
      dispatch(updateProduct({ id: product._id, updatedFields }));

      close();
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  const { t } = useTranslation();

  return (
    <Modal productEdit={true}>
      <div className=" bg-white rounded-md w-full h-[50vh] p-2">
        <ModalHeader>{t('Product Edit')}</ModalHeader>
        <ModalBody>
          <div className="border  p-2 mt-6 ">
            <form
              onSubmit={handleSubmit(handleSave)}
              className="flex justify-between lg:flex-row flex-col gap-4"
            >
              <div className="md:w-1/3">
                <ProductImageUpload
                  setValue={setValue}
                  imagePreview={imagePreview}
                  setImagePreview={setImagePreview}
                />
                <div className="flex mt-2 mb-2 flex-col gap-1">
                  <label className="block text-sm font-medium text-gray-700">
                    {t('Quantity Order Level')}
                  </label>
                  <input
                    name="quantityOrderLevelStock"
                    type="text"
                    {...register("quantityOrderLevelStock")}
                    className="mt-1 px-3 py-2 w-full focus:outline-none border rounded-md focus:ring focus:ring-indigo-200"
                    placeholder={`${t('Enter the Quantity Order level')}`}
                  />
                </div>
                <div className="flex flex-col gap-1">
                  <label className="block text-sm font-medium text-gray-700">
                    {t('Consumption')}
                  </label>
                  <input
                    name="consumption"
                    type="text"
                    {...register("consumption")}
                    className="mt-1 px-3 py-2 w-full focus:outline-none border rounded-md focus:ring focus:ring-indigo-200"
                    placeholder={`${t('Enter the Consumption Rate')}`}
                  />
                </div>
              </div>
              <div className="lg:w-1/3 flex flex-col gap-2">
                <div className="flex flex-col gap-1">
                  <label className="block text-sm font-medium text-gray-700">
                    {t('Product Name')}
                  </label>
                  <input
                    {...register("productName")}
                    name="productName"
                    type="text"
                    className="mt-1 px-3 py-2 w-full focus:outline-none border rounded-md focus:ring focus:ring-indigo-200"
                    placeholder={`${t('Enter the product name')}`}
                  />
                </div>
                <SelectInput
                  name="supplierId"
                  register={register("supplierId")}
                  value={watch("supplierId")}
                  placeholder={`${t('Enter supplier name')}`}
                  label={`${t('Supplier name')}`}
                  options={suppliers.map((supplier) => ({
                    label: supplier.supplierName,
                    value: supplier._id,
                  }))}
                />
                <div className=" flex flex-col gap-1">
                  <label className="block text-sm font-medium text-gray-700">
                    {t('Product Quantity')}
                  </label>
                  <div className="relative">
                    <input
                      {...register("quantity")}
                      inputMode="numeric"
                      type="number"
                      value={watch("quantity")}
                      className="mt-1 appearance-none px-3 py-2 w-full focus:outline-none border rounded-md focus:ring focus:ring-indigo-200 pr-12"
                      placeholder={`${t('Enter the Quantity')}`}
                    />
                    <Button
                      onClick={handleDecrement}
                      type="button"
                      buttonStyle="absolute right-0 rotate-180 bottom-[33%] transform translate-y-1/2"
                    >
                      <SelectUpIcon className="h-3 w-3 text-gray-400" />
                    </Button>
                    <Button
                      onClick={handleIncrement}
                      type="button"
                      buttonStyle="absolute right-0 rotate-180 top-[40%] transform -translate-y-1/2"
                    >
                      <SelectIcon className="h-3 w-3 text-gray-400" />
                    </Button>
                  </div>
                </div>
                <div className="flex flex-col gap-1">
                  <label className="block text-sm font-medium text-gray-700">
                    {t('Delivery Time')}
                  </label>
                  <input
                    name="deliveryTime"
                    type="text"
                    {...register("deliveryTime", {
                      required: "Delivery Time is Required",
                    })}
                    className="mt-1 px-3 py-2 w-full focus:outline-none border rounded-md focus:ring focus:ring-indigo-200"
                    placeholder={`${t('Enter the Delivery Time')}`}
                  />
                </div>
                <div className="mb-4 flex flex-col gap-1">
                  <label className="block text-sm font-medium text-gray-700">
                    {t('Product Description')}
                  </label>
                  <textarea
                    {...register("productDescription")}
                    name="productDescription"
                    className="mt-1 px-3 py-2 w-full resize-none focus:outline-none border rounded-md focus:ring focus:ring-indigo-200"
                    placeholder={`${t('Enter the product description')}`}
                    rows={4}
                  />
                </div>
              </div>
              <div className="lg:w-1/3 flex flex-col gap-2">
                <SelectInput
                  value={watch("categoryId")}
                  register={register("categoryId")}
                  name="categoryId"
                  placeholder={`${t('Enter product category')}`}
                  label={`${t('Product Category')}`}
                  options={categories.map((category) => ({
                    label: category.categoryName,
                    value: category._id,
                  }))}
                />
                <SelectInput
                  value={watch("inventoryTypeId")}
                  register={register("inventoryTypeId")}
                  name="inventoryTypeId"
                  placeholder={`${t('Select inventory type')}`}
                  label={`${t('Inventory Type')}`}
                  options={inventoryTypes.map((type) => ({
                    label: type.inventoryTypeName,
                    value: type._id,
                  }))}
                />
                <div className="flex flex-col gap-1">
                  <label className="block text-sm font-medium text-gray-700">
                    {t('Barcode Number')}
                  </label>
                  <input
                    {...register("barcode")}
                    name="barcode"
                    type="number"
                    className="mt-1 px-3 py-2 w-full focus:outline-none border rounded-md focus:ring focus:ring-indigo-200"
                    placeholder={`${t('Enter the Barcode Number')}`}
                  />
                </div>
                <div className="mb-4 flex flex-col gap-1">
                  <label className="block text-sm font-medium text-gray-700">
                    {t('Product ID')}
                  </label>
                  <input
                    {...register("productId")}
                    name="productId"
                    type="text"
                    className="mt-1 px-3 py-2 w-full focus:outline-none border rounded-md focus:ring focus:ring-indigo-200"
                    placeholder={`${t('Enter the Product ID')}`}
                  />
                </div>
                <div className="mb-4 flex flex-col gap-1">
                  <label className="block text-sm font-medium text-gray-700">
                    {t('P. Level')}
                  </label>
                  <input
                    {...register("productLevel", {
                      required: "productLevel is required",
                    })}
                    name="productLevel"
                    type="text"
                    className="mt-1 px-3 py-2 w-full focus:outline-none border rounded-md focus:ring focus:ring-indigo-200"
                    placeholder={`${t('Enter the Product Level')}`}
                  />
                </div>

                <ModalFooter>
                  <div className="flex gap-2 items-end self-end mb-2 lg:mb-0 mt-2 md:mt-0 lg:-translate-y-3 flex-grow">
                    <Button
                      onClick={close}
                      type="button"
                      buttonStyle="text-gray-700 bg-gray-200 "
                    >
                      {t('Cancel')}
                    </Button>
                    <Button buttonStyle="mr-2 text-white bg-primary ">
                      {t('Save')}
                    </Button>
                  </div>
                </ModalFooter>
              </div>
            </form>
          </div>
        </ModalBody>
      </div>
    </Modal>
  );
};
