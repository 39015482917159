import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader } from "../../Common/Modal";
import SelectInput from "../../Common/SelectInput";
import Button from "../../Common/Button";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { addUser } from "../../../features/UserAdd/UserAddThunk";
import { useTranslation } from "react-i18next";

export const AddUserModal = ({ close }) => {
  const { register, handleSubmit, watch } = useForm();
  const [errorMessage, setErrorMessage] = useState(false);

  const dispatch = useDispatch();

  const handleSave = async (data) => {
    const newData = {
      ...data,
      userRole: data.role,
    };
    try {
      dispatch(
        addUser({
          userData: newData,
          onSuccess: () => {
            close();
          },
          onError: (data) => {
            setErrorMessage(data.message);
          },
        })
      );
    } catch (err) {
      throw err;
    }
  };

  const { t } = useTranslation();

  return (
    <Modal>
      <ModalHeader>{t('Add New User')}</ModalHeader>
      <ModalBody>
        <form onSubmit={handleSubmit(handleSave)}>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              {t('User name')}
            </label>
            <input
              type="text"
              className="mt-1 px-3 py-2 w-full focus:outline-none border rounded-md focus:ring focus:ring-indigo-200"
              placeholder={t('Enter Usernme')}
              {...register("userName", { required: true })}
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              {t('Email')}
            </label>
            <input
              type="text"
              name="email"
              className="mt-1 px-3 py-2 w-full focus:outline-none border rounded-md focus:ring focus:ring-indigo-200"
              placeholder={t('Enter Email')}
              {...register("email", { required: true })}
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              {t('Password')}
            </label>
            <input
              type="password"
              className="mt-1 px-3 py-2 w-full focus:outline-none border rounded-md focus:ring focus:ring-indigo-200"
              placeholder={t('Enter password')}
              name="password"
              {...register("password", { required: true })}
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              {t('Department')}
            </label>
            <input
              type="text"
              className="mt-1 px-3 py-2 w-full focus:outline-none border rounded-md focus:ring focus:ring-indigo-200"
              placeholder={t('Enter Department')}
              name="department"
              {...register("department", { required: true })}
            />
          </div>
          <SelectInput
            value={watch("role")}
            name="role"
            placeholder={`${t('Enter Role')}`}
            label={`${t('User Role')}`}
            options={[
              { value: "admin", label: "Admin" },
              { value: "manager", label: "Manager" },
              { label: "User", value: "user" },
            ]}
            register={register("role", { required: true })}
          />

          {errorMessage && <p className="text-red-400">{errorMessage}</p>}
          <div className="flex justify-end gap-2 mt-4">
            <Button buttonStyle="text-gray-700 bg-gray-200" onClick={close}>
              {t('Cancel')}
            </Button>
            <Button buttonStyle="text-white bg-primary" type="submit">
              {t('Save')}
            </Button>
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
};
