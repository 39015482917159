// dataThunks.js

import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchCategories } from "./Categories/CategoriesThunk";
import { fetchInventoryTypes } from "./InventoryType/InventoryThunk";
import { fetchSuppliers } from "./Supplier/SupplierThunk";

export const fetchAllData = createAsyncThunk(
  "data/fetchAll",
  async (_, { dispatch }) => {
    dispatch(fetchCategories());
    dispatch(fetchInventoryTypes());
    dispatch(fetchSuppliers());
    return;
  }
);
