import React, { useEffect, useState } from "react";
import UsersTable from "../../component/Admin/UsersTable";
import { AddIcon } from "../../svgs/Index";
import { AddUserModal } from "../../component/Admin/UserModal/AddUserModal";
import { ModalService, ModalRoot } from "../../component/Common/Modal";
import StandardHeader from "../../component/Common/StandardHeader";
import Button from "../../component/Common/Button";
import { useDispatch } from "react-redux";
import { fetchUsers } from "../../features/UserAdd/UserAddThunk";

import { useTranslation } from "react-i18next";

const usersData = [
  {
    id: 1,
    name: "test",
    userType: "Manager",
    userAccess: "Complete",
  },
];

const ManageUsers = () => {
  const dispatch = useDispatch();
  const [users, setUsers] = useState(usersData);
  const openAddUserModal = () => {
    ModalService.open(AddUserModal, { setUsers });
  };

  useEffect(() => {
    dispatch(fetchUsers());
  }, []);

  const { t } = useTranslation();

  return (
    <div className="mt-6 bg-white rounded-md min-h-[80vh] shadow-md p-4">
      <StandardHeader label={`${t('Manage Users')}`}>
        <Button onClick={openAddUserModal} buttonStyle="bg-primary text-white ">
          <AddIcon className="w-4 h-4" /> {t('Add User')}
        </Button>
      </StandardHeader>
      <UsersTable usersData={users} />
      <ModalRoot />
    </div>
  );
};

export default ManageUsers;
