import React from "react";
import EditProfileModal from "../Profile/EditProfileModal";
import { ModalService } from "../../component/Common/Modal";

const ProfileInput = ({
  label,
  name,
  type,
  placeholder,
  value,
  onChange,
  field,
  formData,
  username,
  updateFormData,
}) => {
  const OpenProfileEditModal = () => {
    ModalService.open(EditProfileModal, {
      placeholder,
      updateFormData,
      value,
      label,
      username,
      field,
      formData,
    });
  };
  return (
    <div className="flex flex-col gap-1 lg:w-3/4 w-full">
      <label className="block text-sm font-medium text-gray-700">{label}</label>
      <div className="relative">
        <input
          name={name}
          type={type}
          value={formData[field]}
          onChange={onChange}
          disabled
          className="mt-1 px-3 py-2 w-full focus:outline-none border rounded-md bg-[#D9D9D94D] focus:ring focus:ring-indigo-200"
          placeholder={placeholder}
        />
        <span
          onClick={OpenProfileEditModal}
          className="font-medium cursor-pointer text-primary absolute right-4 -translate-y-1/2 top-1/2"
        >
          Edit
        </span>
      </div>
    </div>
  );
};

export default ProfileInput;
