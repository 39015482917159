import React, { useState } from "react";
import { SelectIcon } from "../../svgs/Index";

const Tabs = ({ menuItems, setActive, active }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const activeTab = (id) => {
    return active === id
      ? "font-medium text-[#0D1282] border-b-2 border-[#0D1282]"
      : "";
  };

  return (
    <div className="flex flex-wrap items-center justify-start">
      <div className="relative md:hidden">
        <button
          onClick={toggleDropdown}
          className="flex items-center justify-start p-2 focus:outline-none"
        >
          <SelectIcon
            className={`w-4 h-4 ${isDropdownOpen ? "rotate-180" : ""}`}
          />

          <span className="ml-2">
            {menuItems.find((item) => item.id === active)?.label}
          </span>
        </button>
        {isDropdownOpen && (
          <ul className="absolute z-10 w-44 bg-white border border-gray-300 rounded shadow mt-2">
            {menuItems.map(({ label, id }) => (
              <li
                key={id}
                onClick={() => {
                  setActive(id);
                  setIsDropdownOpen(false);
                }}
                className={`cursor-pointer px-4 py-2 ${activeTab(id)}`}
              >
                {label}
              </li>
            ))}
          </ul>
        )}
      </div>

      <div className="hidden md:flex">
        {menuItems.map(({ label, id }) => (
          <li
            key={id}
            onClick={() => setActive(id)}
            className={`cursor-pointer px-4 py-2 ${activeTab(id)}`}
          >
            {label}
          </li>
        ))}
      </div>
    </div>
  );
};

export default Tabs;
