import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "../../Common/Modal";
import Button from "../../Common/Button";
import { deleteSupplier } from "../../../features/Supplier/SupplierThunk";
import { useTranslation } from "react-i18next";

export const DeleteSupplierModal = ({ close, foundSupplier = "" }) => {
  const dispatch = useDispatch();

  const handleSave = async (e) => {
    e.preventDefault();
    try {
      dispatch(deleteSupplier({ id: foundSupplier._id }));
      close();
    } catch (error) {
      console.error("Error adding supplier:", error);
    }
  };

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (e.target.classList.contains("modal-overlay")) {
        close();
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [close]);

  const { t } = useTranslation();

  return (
    <Modal>
      <form onSubmit={handleSave}>
        <ModalHeader>{t('Delete Supplier')}</ModalHeader>
        <ModalBody>
          <div className="mb-4 flex justify-center py-2">
            <p>
              {t('Are you sure you want to delete')}{" "}
              <span className="font-semibold">
                {foundSupplier?.supplierName}
              </span>{" "}
              {t('Supplier')}?
            </p>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="flex justify-end gap-2">
            <Button buttonStyle={"text-gray-700 bg-gray-200"} onClick={close}>
              Cancel
            </Button>
            <Button buttonStyle={"mr-2 text-white bg-[#D20101]"} type="submit">
              Delete
            </Button>
          </div>
        </ModalFooter>
      </form>
    </Modal>
  );
};
