import { useSelector } from "react-redux";
import Button from "../Common/Button";

import { useTranslation } from "react-i18next";

const InventoryProduct = ({
  openModal,
  product,
  openProductEditModal,
  openProductDeleteModal,
}) => {
  const userRole = useSelector((state) => state?.userAuth?.user?.userRole);
  const { t } = useTranslation();

  return (
    <div className="rounded-md shadow-[rgba(0,0,15,0.1)_0px_1px_4px_2px]  flex flex-col justify-between px-4 py-3 h-78 bg-white transition duration-300">
      <div onClick={() => openModal(product)}>
        <div>
          <img
            src={product?.image}
            className="md:h-44 rounded-md w-full md:object-contain"
            alt="product image"
          />
        </div>
        <div className="mt-1">
          <h2 className="font-medium text-[1rem] line-clamp-2">
            {product?.productName}
          </h2>
          <h2 className="font-medium text-[0.8rem] text-gray-500  line-clamp-2">
            {product?.productDescription}
          </h2>
          <div className="flex  mt-2 border-t text-[0.8rem] justify-between items-center">
            <p className="font-medium text-gray-500 flex flex-col">
              {t('Quantity')}<span className="font-normal ">{product?.quantity}</span>
            </p>
            <p className="font-medium text-gray-500 flex flex-col">
              {t('Barcode Number')} <span className="font-normal ">{product?.barcode}</span>
            </p>
          </div>
        </div>
      </div>
      {userRole === "admin" && (
        <div className="flex mt-2 justify-end">
          <Button
            onClick={() => openProductEditModal(product)}
            buttonStyle="bg-primary w-1/2 font-medium flex justify-center text-white"
          >
            {t('Edit')}
          </Button>
          <Button
            onClick={() => openProductDeleteModal(product)}
            buttonStyle="bg-red-600 w-1/2 ml-2 font-medium flex justify-center text-white"
          >
            {t('Delete')}
          </Button>
        </div>
      )}
    </div>
  );
};

export default InventoryProduct;
