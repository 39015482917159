import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "./Modal";
import Button from "./Button";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

const CommonModal = ({
  close,
  title,
  foundData,
  type = "",
  inputName = "",
  inputDescription = "",
  action,
}) => {
  const dispatch = useDispatch();
  const { handleSubmit, register, setValue } = useForm();

  useEffect(() => {
    if (foundData) {
      setValue(inputName, foundData[inputName]);
      setValue(inputDescription, foundData[inputDescription]);
    }
  }, [foundData, inputName, inputDescription, setValue]);

  const onSubmit = async (formData) => {
    try {
      if (type === "add") {
        dispatch(action(formData));
      } else {
        dispatch(action({ ...formData, id: foundData?._id }));
      }

      close();
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const { t } = useTranslation();

  return (
    <Modal>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader>{title}</ModalHeader>
        <ModalBody>
          <div className="mb-4">
            <label className="block text-sm font-medium capitalize text-gray-700">
              {inputName}
            </label>
            <input
              type="text"
              {...register(inputName, {
                required: true,
              })}
              name={inputName}
              className="mt-1 px-3 py-2 w-full placeholder:capitalize focus:outline-none border rounded-md focus:ring focus:ring-indigo-200"
              placeholder={`${t('Enter the name for')} ${title?.split(" ")[1]}`}
            />
          </div>

          <div className="mb-4">
            <label className="block text-sm font-medium capitalize text-gray-700">
              {inputDescription}
            </label>
            <textarea
              cols={5}
              rows={5}
              {...register(inputDescription, {
                required: true,
              })}
              name={inputDescription}
              className="mt-1 resize-none px-3 py-2 w-full placeholder:capitalize focus:outline-none border rounded-md focus:ring focus:ring-indigo-200"
              placeholder={`${t('Enter the description for')} ${title?.split(" ")[1]}`}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="flex justify-end gap-2">
            <Button buttonStyle={"text-gray-700 bg-gray-200"} onClick={close}>
              {t('Cancel')}
            </Button>
            <Button buttonStyle={"mr-2 text-white bg-primary"} type="submit">
              {t('Save')}
            </Button>
          </div>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default CommonModal;
