// Index.js
import React from "react";
import "./index.css";
import Header from "./Header";
import Home from "./Home";


function Index() {
    return (
        <div className="WebPub" dir="rtl">
            <Header />
            <Home />
        </div>


    );
}

export default Index;
