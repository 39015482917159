import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loginWithToken } from "../features/UserAuthSlice";
import { Oval } from "react-loader-spinner";

const PrivateRoute = ({ roles, element }) => {
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userRole, setUserRole] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
    dispatch(
      loginWithToken({
        onSuccess: (role) => {
          setIsAuthenticated(true);
          setLoading(false);
          setUserRole(role);
        },
        onError: () => {
          setIsAuthenticated(false);
          setLoading(false);
        },
      })
    );
  }, [dispatch]);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <Oval
          height={60}
          width={60}
          color="gray"
          visible={true}
          ariaLabel="oval-loading"
          secondaryColor="#E9F2FA"
          strokeWidth={2}
          strokeWidthSecondary={2}
        />
      </div>
    );
  }

  if (!isAuthenticated) {
    return <Navigate to="/sign-in" />;
  }
  console.log(roles)

  if (roles && roles.indexOf(userRole) === -1) {
    // User's role doesn't match any of the allowed roles for this route
    return <Navigate to="/404" />;
  }

  return element ? element : <Outlet />;
};

export default PrivateRoute;
