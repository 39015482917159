import mainLogo from "../../assets/images/logo.png";
import { useTranslation } from 'react-i18next';

const Logo = ({ styles }) => {
  const { t } = useTranslation();
  return (
    <h1
      className={`flex text-sm gap-2 items-center md:text-lg font-bold ${styles}`}
    >
      <img className="w-13 h-12" src={mainLogo} alt="Logo" />{" "}
      <span className="hidden md:block">{t('Management System')}</span>
    </h1>
  );
};

export default Logo;
