import React from "react";
import Table from "../Common/Table";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { fetchTransactions } from "../../features/Transaction/transactionThunk";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const TransactionTable = () => {
  const transactions = useSelector(
    (state) => state?.transactions?.transactions
  );
  const updatedTransactions = transactions.map((trans) => ({
    ...trans,
    productId: trans?.productId?.productName,
    userId: trans?.userId?.userName,
  }));
  console.log(transactions);

  const { t } = useTranslation();

  const columns = [
    {
      header: `${t('Product Name')}`,
      field: "productId",
    },
    {
      header: `${t('Date')}`,
      field: "createdAt",
    },
    {
      header: `${t('Product Quantity')}`,
      field: "quantity",
    },

    {
      header: `${t('Action')}`,
      field: "action",
      className: (item) =>
        `${item.action === "Pull"
          ? "bg-red-500 py-2 text-white"
          : "bg-green-500 py-2 text-white"
        }`,
    },
    {
      header: `${t('User')}`,
      field: "userId",
    },
  ];

  return <Table columns={columns} data={updatedTransactions} />;
};

export default TransactionTable;
