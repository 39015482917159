import React, { useState, useEffect } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "../../Common/Modal";
import { SelectIcon } from "../../../svgs/Index";
import demoImage from "../../../assets/images/demo-1.jpg";
import Button from "../../Common/Button";
import UserAvatar from "../../Common/UserAvatar";
import { useDispatch } from "react-redux";
import { updateUser } from "../../../features/UserAdd/UserAddThunk";

export const EditUserModal = ({ close, usersData }) => {
  const dispatch = useDispatch();

  let [formData, setFormData] = useState({
    userName: usersData?.userName,
    department: usersData?.department,
    userRole: usersData?.userRole,
    email: usersData?.email,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSave = () => {
    dispatch(updateUser({ id: usersData?._id, ...formData }));
    close();
  };
  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (e.target.classList.contains("modal-overlay")) {
        close();
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [close]);
  return (
    <Modal>
      <ModalHeader>Edit User</ModalHeader>
      <ModalBody>
        <div className="flex justify-center">
          <UserAvatar size="50" name={formData.userName} />
        </div>
        {/* Username input */}
        <div className="mb-4 mt-4">
          <label className="block text-sm font-medium text-gray-700">
            Username
          </label>
          <input
            type="text"
            className="mt-1 px-3 py-2 w-full focus:outline-none border rounded-md focus:ring focus:ring-indigo-200"
            placeholder="Enter User Name"
            value={formData.userName}
            name="userName"
            onChange={handleChange}
          />
        </div>

        {/* User Type select */}
        <div className="mb-4 relative">
          <label className="block mb-1 text-sm font-medium text-gray-700">
            User Role
          </label>
          <div className="relative">
            <select
              name="userRole"
              className="pl-3 focus:outline-none py-2 w-full border rounded-md focus:ring focus:ring-indigo-200 appearance-none"
              value={formData.userRole}
              onChange={handleChange}
            >
              <option value="admin">Admin</option>
              <option value="manager">Manager</option>
              <option value="user">User</option>
            </select>
            <div className="absolute inset-y-0 right-0 top-[55%] -translate-y-[50%] pr-3 pointer-events-none">
              <SelectIcon className="h-4 w-4 text-gray-400" />
            </div>
          </div>
        </div>

        {/* User Access select */}
        <div className="mb-4 relative">
          <label className="block mb-1 text-sm font-medium text-gray-700">
            Email
          </label>
          <input
            type="text"
            className="mt-1 px-3 py-2 w-full focus:outline-none border rounded-md focus:ring focus:ring-indigo-200"
            placeholder="Enter Your Email"
            value={formData.email}
            name="email"
            onChange={handleChange}
          />
        </div>
        <div className="mb-4 relative">
          <label className="block mb-1 text-sm font-medium text-gray-700">
            Department
          </label>
          <input
            type="text"
            className="mt-1 px-3 py-2 w-full focus:outline-none border rounded-md focus:ring focus:ring-indigo-200"
            placeholder="Enter Your Email"
            value={formData.department}
            name="department"
            onChange={handleChange}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="flex justify-end gap-2">
          <Button buttonStyle="text-gray-700 bg-gray-200 " onClick={close}>
            Cancel
          </Button>
          <Button buttonStyle="text-white bg-primary" onClick={handleSave}>
            Save
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};
