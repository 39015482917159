import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
const fetchData = async (token) => {
  try {
    const response = await axios.post(
      "/api/users/verify-token",
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const loginWithToken = createAsyncThunk(
  "auth/loginWithToken",
  async ({ onSuccess, onError }, { dispatch }) => {
    const storedToken = localStorage.getItem("token");
    if (storedToken) {
      try {
        const response = await fetchData(storedToken);
        if (response?.status === "success") {
          const userType = response?.data?.user?.userRole;

          if (onSuccess) {
            onSuccess(userType);
          }
          return response;
        } else {
          if (onError) {
            onError();
          }
          console.log("Token validation failed");
          dispatch(logout());
        }
      } catch (error) {
        if (onError) {
          onError();
        }
        console.log("Error during token validation:", error);
        dispatch(logout());
      }
    } else {
      if (onError) {
        onError();
      }
      console.log("No token found");
      dispatch(logout());
    }
  }
);

export const updateSingleUser = createAsyncThunk(
  "auth/updateSingleUser",
  async (data) => {
    try {
      const response = await axios.put(`/api/users/update/${data?.id}`, data);
      return response.data;
    } catch (err) {
      throw err;
    }
  }
);

const initialState = {
  user: null,
  token: null,
  isAuthenticated: false,
};

const UserAuthSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginSuccess(state, action) {
      state.isAuthenticated = true;
      state.token = action?.payload?.data?.token;
      state.user = action?.payload?.data?.user;
    },
    logout: (state) => {
      state.user = null;
      state.isAuthenticated = false;
      localStorage.removeItem("token");
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginWithToken.fulfilled, (state, action) => {
        if (action?.payload?.status === "success") {
          state.isAuthenticated = true;
          state.token = action?.payload?.data?.token;
          state.user = action?.payload?.data?.user;
        } else {
          state.token = null;
          state.isAuthenticated = false;
          state.user = null;
        }
      })
      .addCase(updateSingleUser.fulfilled, (state, action) => {
        state.user = action.payload.data;
      });
  },
});

let userAuthReducer = UserAuthSlice.reducer;
export const { loginSuccess, logout } = UserAuthSlice.actions;

export { userAuthReducer };
