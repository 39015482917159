import React from "react";

const FormInput = ({
  label,
  type,
  placeholder,
  id,
  value,
  onChange,
  error,
  register,
}) => {
  return (
    <div className="flex flex-col">
      <label className="font-medium mb-1" htmlFor={id}>
        {label}
      </label>
      <input
        className={`border focus:outline-none px-2 py-2 rounded-md ${error ? "border-red-500" : ""
          }`}
        placeholder={placeholder}
        id={id}
        type={type}
        value={value}
        onChange={onChange}
        {...register}
      />
      {error && <span className="text-sm text-red-500">{error}</span>}
    </div>
  );
};

export default FormInput;
