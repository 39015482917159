// Sidebar.js
import React from "react";

function Sidebar() {
    return (
        <div className="sidebar">
            <ul>
                <li>Category 1</li>
                <li>Category 2</li>
                <li>Category 3</li>
                {/* Add more categories or links as needed */}
            </ul>
        </div>
    );
}

export default Sidebar;
