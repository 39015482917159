import React from "react";
import { EditLogo, DeleteLogo } from "../../svgs/Index";
import { DeleteUserModal } from "./UserModal/DeleteUserModal";
import { EditUserModal } from "./UserModal/EditUserModal";
import { ModalService } from "../Common/Modal";
import Table from "../Common/Table";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const UsersTable = () => {
  const allUsers = useSelector((state) => state?.users?.users);
  const loggedUser = useSelector((state) => state?.userAuth?.user);
  const filteredUser = allUsers?.filter(
    (user) => user?._id !== loggedUser?._id
  );

  const OpenDeleteUserModal = (id) => {
    const foundUser = allUsers?.find((user) => user?._id === id);
    ModalService.open(DeleteUserModal, { foundUser });
  };

  const OpenEditUserModal = (id) => {
    const usersData = allUsers?.find((user) => user?._id === id);

    ModalService.open(EditUserModal, { usersData });
  };

  const renderActions = (id) => {
    return (
      <div className="inline-flex cursor-pointer items-center">
        <DeleteLogo onClick={() => OpenDeleteUserModal(id)} />
        <EditLogo onClick={() => OpenEditUserModal(id)} className="ml-2" />
      </div>
    );
  };

  const { t } = useTranslation();
  const columns = [
    {
      header: `${t('Name')}`,
      subheader: "",
      field: "userName",
    },
    {
      header: `${t('Email')}`,
      subheader: "",
      field: "email",
    },
    {
      header: `${t('User Type')}`,
      subheader: "",
      field: "userRole",
    },

    {
      header: `${t('Department')}`,
      subheader: "",
      field: "department",
    },
  ];

  return (
    <Table
      columns={columns}
      data={filteredUser}
      renderActions={renderActions}
    />
  );
};

export default UsersTable;
