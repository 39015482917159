import { userAuthReducer } from "../features/UserAuthSlice";
import navigationReducer from "../features/NavigationSlice";
import categoriesReducer from "../features/Categories/CategoriesSlice";
import { configureStore } from "@reduxjs/toolkit";
import inventoryTypeReducer from "../features/InventoryType/InventoryTypeSlice";
import supplierReducer from "../features/Supplier/SupplierSlice";
import productReducer from "../features/product/productSlice";
import usersReducer from "../features/UserAdd/UserAddSlice";
import transactionReducer from "../features/Transaction/transactionSlice";
const store = configureStore({
  reducer: {
    userAuth: userAuthReducer,
    navigation: navigationReducer,
    categories: categoriesReducer,
    inventoryTypes: inventoryTypeReducer,
    suppliers: supplierReducer,
    products: productReducer,
    users: usersReducer,
    transactions: transactionReducer,
  },
});

export default store;
