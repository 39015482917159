import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

//all inventory types
export const fetchSuppliers = createAsyncThunk(
  "suppliers/fetcSuppliers",
  async () => {
    try {
      const response = await axios.get("/api/supplier");
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

//add inventory type
export const addSupplier = createAsyncThunk(
  "suppliers/addSupplier",
  async (data) => {
    try {
      const response = await axios.post(`/api/supplier`, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

//update inventory type
export const updateSupplier = createAsyncThunk(
  "suppliers/updateSupplier",
  async (data, { dispatch }) => {
    try {
      const response = await axios.put(`/api/supplier/${data.id}`, data);
      dispatch(fetchSuppliers());
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

//delete inventory type
export const deleteSupplier = createAsyncThunk(
  "suppliers/deleteSupplier",
  async ({ id }, { dispatch }) => {
    try {
      const response = await axios.delete(`/api/supplier/${id}`);
      dispatch(fetchSuppliers());
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
