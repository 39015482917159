import React, { useState } from "react";
import Tabs from "../../component/Common/Tabs";
import Transaction from "./Transaction";
import QuantityReport from "./QuantityReport";
import { useTranslation } from "react-i18next";




const Report = () => {

  const { t } = useTranslation();

  const menuItems = [
    { label: `${t('Quantity Report')}`, id: 1 },
    { label: `${t('Transaction')}`, id: 2 },
  ];


  const [active, setActive] = useState(1);
  return (
    <div className="min-h-[80vh]">
      {" "}
      <header className="">
        <ul className="flex text-gray-500 justify-center w-fit md:mx-auto border-b border-gray-300 gap-4">
          <Tabs setActive={setActive} active={active} menuItems={menuItems} />
        </ul>
      </header>
      <main className="">{active === 2 && <Transaction />}</main>
      <main className="">{active === 1 && <QuantityReport />}</main>
    </div>
  );
};

export default Report;
