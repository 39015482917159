import React from "react";

const StandardHeader = ({ label, children }) => {
  return (
    <header className="flex justify-between items-center">
      <h2 className="font-medium text-lg">{label}</h2>
      <div> {children}</div>
    </header>
  );
};

export default StandardHeader;
