// routes.js
import Home from "./pages/Home";
import Admin from "./pages/Admin/Index";
import Inventory from "./pages/Inventory/Index";
import Report from "./pages/Report/Index";
import UserProfile from "./pages/UserProfile/Index";

const routes = [
  {
    path: "/system",
    element: <Home />,
    private: true,
    children: [
      // Admin Routes
      {
        path: "admin",
        element: <Admin />,
        roles: ["admin"], // Change to an array
      },
      // Manager and User Routes
      {
        path: "report",
        element: <Report />,
        roles: ["admin", "manager"], // Change to an array
      },
      {
        path: "inventory",
        element: <Inventory />,
        roles: ["admin", "manager", "user"], // Change to an array
      },
      {
        path: "user-profile",
        element: <UserProfile />,
        roles: ["admin", "manager", "user"], // Change to an array
      },
    ],
  },
];

export default routes;
