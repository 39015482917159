// navigationSlice.js
import { createSlice } from "@reduxjs/toolkit";

const navigationSlice = createSlice({
  name: "Navigation",
  initialState: {
    isNavOpen: false,
  },
  reducers: {
    openNav(state) {
      state.isNavOpen = true;
    },
    closeNav(state) {
      state.isNavOpen = false;
    },
    toggleNav: (state) => {
      state.isNavOpen = !state.isNavOpen;
    },
  },
});
export const { openNav, closeNav, toggleNav } = navigationSlice.actions;

export default navigationSlice.reducer;
