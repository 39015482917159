import React from "react";
import { MenuItem } from "react-pro-sidebar";
import { Link, useLocation } from "react-router-dom";

const SidebarMenuItem = ({ to, icon, text, onClick }) => {
  const location = useLocation();
  const isActive = location.pathname === "/" + to;

  return (
    <MenuItem
      onClick={onClick}
      className={`border-b gap-2 py-1 relative after:-left-4 after:absolute after:top-[50%] after:h-[80%] after:-translate-y-[50%] after:rounded after:w-0 after:transition-all after:duration-300 after:ease-in-out  after:content-[''] ${
        isActive ? "after:bg-primary after:w-1" : ""
      }`}
      component={<Link to={to} />}
    >
      <div
        className={`flex items-center gap-4 group ${
          isActive ? "text-primary" : "hover:text-primary"
        }`}
      >
        {icon({ isActive })}
        <span className={`text-sm font-semibold `}>{text}</span>
      </div>
    </MenuItem>
  );
};

export default SidebarMenuItem;
