import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "../Common/Modal";
import { SelectUpIcon, SelectIcon } from "../../svgs/Index";
import Button from "../Common/Button";
import { useDispatch } from "react-redux";
import { createTransaction } from "../../features/Transaction/transactionThunk";
import { useTranslation } from "react-i18next";


export const InventoryActionModal = ({
  close,
  userId,
  productId,
  productQuantity,
}) => {
  const dispatch = useDispatch();

  const [quantity, setQuantity] = useState(null);
  const handleIncrement = () => {
    setQuantity(quantity + 1);
  };

  const handleDecrement = () => {
    console.log(quantity, productQuantity);
    if (quantity > -productQuantity + 1) {
      setQuantity(quantity - 1);
    }
  };

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (e.target.classList.contains("modal-overlay")) {
        close();
      }
    };

    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [close]);

  const handleTransaction = async (e) => {
    e.preventDefault();
    try {
      if (quantity < 0) {
        dispatch(
          createTransaction({
            quantity,
            action: "pull",
            userId,
            productId,
          })
        );
      } else {
        dispatch(
          createTransaction({
            quantity,
            action: "push",
            userId,
            productId,
          })
        );
      }

      //to add clean history greater than 2 months

      close();
    } catch (err) {
      throw err;
    }
  };

  const { t } = useTranslation();

  return (
    <Modal>
      <ModalHeader>{t('Inventory Action')}</ModalHeader>
      <form onSubmit={handleTransaction}>
        <ModalBody>
          <div className="mb-4 flex flex-col gap-1">
            <label className="block text-sm font-medium text-gray-700">
              {t('Quantity')}
            </label>
            <div className="relative">
              <input
                inputMode="numeric"
                type="number"
                value={quantity}
                className="mt-1 appearance-none px-3 py-2 w-full focus:outline-none border rounded-md focus:ring focus:ring-indigo-200 pr-12"
                placeholder={`${t('Quantity')}`}
                onChange={(e) => {
                  const newQuantity = parseInt(e.target.value, 10);
                  if (!isNaN(newQuantity) && newQuantity > -productQuantity) {
                    setQuantity(newQuantity);
                  }
                }}
              />
              <Button
                onClick={handleDecrement}
                type="button"
                buttonStyle="absolute right-4 rotate-180 bottom-[33%] transform translate-y-1/2"
              >
                <SelectUpIcon className="h-3 w-3 text-gray-400" />
              </Button>
              <Button
                onClick={handleIncrement}
                type="button"
                buttonStyle="absolute right-4 rotate-180 top-[40%] transform -translate-y-1/2"
              >
                <SelectIcon className="h-3 w-3 text-gray-400" />
              </Button>
            </div>
          </div>
          <div className="flex gap-2 justify-center">
            <Button
              onClick={handleDecrement}
              type={"button"}
              buttonStyle={"bg-[#D20101] text-white"}
            >
              {t('Reduce')}
            </Button>
            <Button
              onClick={handleIncrement}
              type={"button"}
              buttonStyle={"bg-[#03A100] text-white"}
            >
              {t('Add')}
            </Button>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="flex justify-end gap-2 mt-16">
            <Button onClick={close} buttonStyle={"bg-gray-200 text-black"}>
              {t('Cancel')}
            </Button>
            <Button type={"submit"} buttonStyle={"bg-primary text-white"}>
              {t('Save')}
            </Button>
          </div>
        </ModalFooter>
      </form>
    </Modal>
  );
};
