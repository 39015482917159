import React, { useEffect } from "react";
import StandardHeader from "../../component/Common/StandardHeader";
import InventoryTypeTable from "../../component/Admin/InventoryTypeTable";
import { AddIcon } from "../../svgs/Index";
import Button from "../../component/Common/Button";
import { ModalService, ModalRoot } from "../../component/Common/Modal";
import { useDispatch } from "react-redux";
import {
  addInventoryType,
  fetchInventoryTypes,
} from "../../features/InventoryType/InventoryThunk";
import CommonModal from "../../component/Common/CommonModal";

import { useTranslation } from "react-i18next";

const InventoryType = () => {
  const dispatch = useDispatch();
  const openInventoryTypeModal = () => {
    ModalService.open(CommonModal, {
      title: `${t('Inventory Type')}`,
      type: "add",
      inputName: "inventoryTypeName",
      inputDescription: "inventoryTypeDescription",
      action: addInventoryType,
    });
  };
  useEffect(() => {
    dispatch(fetchInventoryTypes());
  }, []);

  const { t } = useTranslation();
  return (
    <div className="mt-6 bg-white min-h-[80vh] rounded-md shadow-md p-4">
      <StandardHeader label={`${t('Inventory Type')}`}>
        <Button
          onClick={openInventoryTypeModal}
          buttonStyle="bg-primary text-white "
        >
          <AddIcon className="w-4 h-4" /> {t('Add Inventory')}
        </Button>
      </StandardHeader>
      <InventoryTypeTable />
      <ModalRoot />
    </div>
  );
};

export default InventoryType;
