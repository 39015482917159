import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { fetchProducts } from "../product/productThunk";
export const createTransaction = createAsyncThunk(
  "transaction/createTransaction",
  async (data, { dispatch }) => {
    try {
      const response = await axios.post("/api/transactions", data);
      dispatch(fetchProducts());
      return response?.data;
    } catch (err) {
      throw err;
    }
  }
);

export const fetchTransactions = createAsyncThunk(
  "transaction/fetchTransactions",
  async () => {
    try {
      const response = await axios.get("/api/transactions");

      return response?.data;
    } catch (err) {
      throw err;
    }
  }
);
