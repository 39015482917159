import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

//all inventory types
export const fetchInventoryTypes = createAsyncThunk(
  "inventoryTypes/fetchInventoryTypes",
  async () => {
    try {
      const response = await axios.get("/api/inventoryType");
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

//add inventory type
export const addInventoryType = createAsyncThunk(
  "inventoryTypes/addInventoryType",
  async (data) => {
    try {
      const response = await axios.post(`/api/inventoryType`, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

//update inventory type
export const updateInventoryType = createAsyncThunk(
  "inventoryTypes/updateInventoryType",
  async (data, { dispatch }) => {
    try {
      const response = await axios.put(`/api/inventoryType/${data.id}`, data);
      dispatch(fetchInventoryTypes());
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

//delete inventory type
export const deleteInventoryType = createAsyncThunk(
  "inventoryTypes/deleteInventoryType",
  async ({ id }, { dispatch }) => {
    try {
      const response = await axios.delete(`/api/inventoryType/${id}`);

      dispatch(fetchInventoryTypes());
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
