import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./index.css";
import PrivateRoute from "./component/PrivateRoutes";
import "react-toastify/dist/ReactToastify.css";
import SignIn from "./pages/SignIn";
import routes from "./routes"; // Import the routes array from routes.js
import Index from "./pages/Catalog/Index";
import './i18n'
import CatalogMain from "./pages/Catalog/CatalogMain";

function App() {
  return (

    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Index />} />
        <Route path="/CatalogMain" element={<CatalogMain />} />

        <Route path="/sign-in" element={<SignIn />} />

        {/* Use routes defined in routes.js */}
        {routes.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            element={
              <PrivateRoute roles={route.roles} element={route.element} />
            }
          >
            {route.children &&
              route.children.map((childRoute, childIndex) => (
                <Route
                  key={childIndex}
                  path={childRoute.path}
                  element={childRoute.element}
                />
              ))}
          </Route>
        ))}

        {/* Handle 404 */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

function NotFound() {
  return <div>404 Not found</div>;
}

export default App;
