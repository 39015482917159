export { ReactComponent as AddIcon } from "../assets/images/add.svg";
export { ReactComponent as SelectIcon } from "../assets/images/select.svg";
export { ReactComponent as SelectUpIcon } from "../assets/images/selectUp.svg";
export { ReactComponent as UploadIcon } from "../assets/images/uploadPic.svg";
export { ReactComponent as EditLogo } from "../assets/images/edit.svg";
export { ReactComponent as DeleteLogo } from "../assets/images/delete.svg";
export { ReactComponent as CalendarIcon } from "../assets/images/calendar.svg";
export const AdminIcon = ({ isActive }) => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3_237)">
      <path
        d="M3.75 10V22.5C3.75 22.8315 3.8817 23.1495 4.11612 23.3839C4.35054 23.6183 4.66848 23.75 5 23.75H10V17.5C10 17.1685 10.1317 16.8505 10.3661 16.6161C10.6005 16.3817 10.9185 16.25 11.25 16.25H13.75C14.0815 16.25 14.3995 16.3817 14.6339 16.6161C14.8683 16.8505 15 17.1685 15 17.5V23.75H20C20.3315 23.75 20.6495 23.6183 20.8839 23.3839C21.1183 23.1495 21.25 22.8315 21.25 22.5V10M1.25 12.5L12.5 1.25L23.75 12.5"
        className={`group-hover:stroke-[#0D1282] stroke-black ${
          isActive ? "stroke-[#0D1282]" : ""
        }`}
        strokeWidth="2.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_3_237">
        <rect width="25" height="25" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const ReportIcon = ({ isActive }) => (
  <svg
    width="23"
    height="24"
    viewBox="0 0 23 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3_239)">
      <path
        d="M1.33331 16V22.6667M7.99998 10.6667V22.6667M14.6666 16V22.6667M21.3333 10.6667V22.6667M1.33331 9.33334L7.99998 1.33334L14.6666 9.33334L21.3333 1.33334"
        className={`group-hover:stroke-[#0D1282] stroke-black ${
          isActive ? "stroke-[#0D1282]" : ""
        }`}
        strokeWidth="2.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_3_239">
        <rect width="22.6667" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const InventoryIcon = ({ isActive }) => (
  <svg
    className=""
    width="23"
    height="25"
    viewBox="0 0 23 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3_242)">
      <path
        d="M15 2.5H20C20.3315 2.5 20.6495 2.6317 20.8839 2.86612C21.1183 3.10054 21.25 3.41848 21.25 3.75V22.5C21.25 22.8315 21.1183 23.1495 20.8839 23.3839C20.6495 23.6183 20.3315 23.75 20 23.75H2.5C2.16848 23.75 1.85054 23.6183 1.61612 23.3839C1.3817 23.1495 1.25 22.8315 1.25 22.5V3.75C1.25 3.41848 1.3817 3.10054 1.61612 2.86612C1.85054 2.6317 2.16848 2.5 2.5 2.5H7.5M15 2.5V6.25H7.5V2.5M15 2.5C15 2.16848 14.8683 1.85054 14.6339 1.61612C14.3995 1.3817 14.0815 1.25 13.75 1.25H8.75C8.41848 1.25 8.10054 1.3817 7.86612 1.61612C7.6317 1.85054 7.5 2.16848 7.5 2.5M6.25 6.25H16.25M10 12.5H16.25M6.25 12.5H6.2625M6.25 17.5H6.2625M10 17.5H16.25"
        className={`group-hover:stroke-[#0D1282] stroke-black ${
          isActive ? "stroke-[#0D1282]" : ""
        }`}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_3_242">
        <rect width="22.5" height="25" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
