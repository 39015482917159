import { toast } from "react-toastify";

export const notifyAndNavigate = (navigate, message, destination) => {
  toast(message, {
    onClose: () => {
      navigate(destination); // Navigate to the specified destination after toast closes
    },
  });
};

export const notify = (message) => {
  toast(message);
};
