import React, { useState } from "react";
import { InventoryActionModal } from "./InventoryActionModal";
import { ModalService, ModalRoot } from "../../component/Common/Modal";
import InventoryProduct from "./InventoryProduct";
import { useSelector } from "react-redux";
import { ProductEditModal } from "./productEditModal";
import { InventoryModalRoot } from "./modal";
import { ProductDeleteModal } from "./productDeleteModal";

const InventoryProducts = ({ products }) => {
  const userId = useSelector((state) => state?.userAuth?.user._id);
  const [renderModalType, setRenderModalType] = useState("");
  const openInventoryActionModal = (product) => {
    setRenderModalType("productAction");
    ModalService.open(InventoryActionModal, {
      userId,
      productId: product?._id,
      productQuantity: product?.quantity,
    });
  };
  const openProductEditModal = (product) => {
    setRenderModalType("productEdit");
    ModalService.open(ProductEditModal, { product });
  };
  const openProductDeleteModal = (foundData) => {
    console.log(foundData);
    ModalService.open(ProductDeleteModal, { foundData });
  };

  return (
    <div className=" grid-cols-1 min-w-fit border p-4 mt-6 grid gap-4 md:grid-cols-3 lg:grid-cols-4">
      {products.map((product) => (
        <InventoryProduct
          key={product?._id}
          product={product}
          openModal={openInventoryActionModal}
          openProductEditModal={openProductEditModal}
          openProductDeleteModal={openProductDeleteModal}
        />
      ))}
      {renderModalType === "producAction" ? (
        <ModalRoot />
      ) : (
        <InventoryModalRoot />
      )}
    </div>
  );
};

export default InventoryProducts;
