// ProductCatalog.js
import React from "react";
import ProductCatalog from "./ProductCatalog"; // Import the Product component

const products = [
    {
        id: 1,
        name: "Product 1",
        description: "Description for Product 1",
        price: 19.99,
        imageUrl: "https://res.cloudinary.com/datnvhs9h/image/upload/v1695844332/x5hksqmueh0bp0tdcumb.jpg",
    },
    {
        id: 2,
        name: "Product 2",
        description: "Description for Product 2",
        price: 24.99,
        imageUrl: "https://res.cloudinary.com/datnvhs9h/image/upload/v1693374973/enbiyg5geapioirlvjp6.jpg",
    },
    {
        id: 2,
        name: "Product 2",
        description: "Description for Product 2",
        price: 24.99,
        imageUrl: "https://res.cloudinary.com/datnvhs9h/image/upload/v1693374973/enbiyg5geapioirlvjp6.jpg",
    },
    {
        id: 2,
        name: "Product 2",
        description: "Description for Product 2",
        price: 24.99,
        imageUrl: "https://res.cloudinary.com/datnvhs9h/image/upload/v1693374973/enbiyg5geapioirlvjp6.jpg",
    },
    {
        id: 2,
        name: "Product 2",
        description: "Description for Product 2",
        price: 24.99,
        imageUrl: "https://res.cloudinary.com/datnvhs9h/image/upload/v1693374973/enbiyg5geapioirlvjp6.jpg",
    },
    {
        id: 2,
        name: "Product 2",
        description: "Description for Product 2",
        price: 24.99,
        imageUrl: "https://res.cloudinary.com/datnvhs9h/image/upload/v1693374973/enbiyg5geapioirlvjp6.jpg",
    },
    {
        id: 2,
        name: "Product 2",
        description: "Description for Product 2",
        price: 24.99,
        imageUrl: "https://res.cloudinary.com/datnvhs9h/image/upload/v1693374973/enbiyg5geapioirlvjp6.jpg",
    },
    {
        id: 2,
        name: "Product 2",
        description: "Description for Product 2",
        price: 24.99,
        imageUrl: "https://res.cloudinary.com/datnvhs9h/image/upload/v1693374973/enbiyg5geapioirlvjp6.jpg",
    },
    {
        id: 2,
        name: "Product 2",
        description: "Description for Product 2",
        price: 24.99,
        imageUrl: "https://res.cloudinary.com/datnvhs9h/image/upload/v1693374973/enbiyg5geapioirlvjp6.jpg",
    },
    {
        id: 2,
        name: "Product 2",
        description: "Description for Product 2",
        price: 24.99,
        imageUrl: "https://res.cloudinary.com/datnvhs9h/image/upload/v1693374973/enbiyg5geapioirlvjp6.jpg",
    },
    {
        id: 2,
        name: "Product 2",
        description: "Description for Product 2",
        price: 24.99,
        imageUrl: "https://res.cloudinary.com/datnvhs9h/image/upload/v1693374973/enbiyg5geapioirlvjp6.jpg",
    },
    {
        id: 2,
        name: "Product 2",
        description: "Description for Product 2",
        price: 24.99,
        imageUrl: "https://res.cloudinary.com/datnvhs9h/image/upload/v1693374973/enbiyg5geapioirlvjp6.jpg",
    },
    {
        id: 2,
        name: "Product 2",
        description: "Description for Product 2",
        price: 24.99,
        imageUrl: "https://res.cloudinary.com/datnvhs9h/image/upload/v1693374973/enbiyg5geapioirlvjp6.jpg",
    },
    {
        id: 2,
        name: "Product 2",
        description: "Description for Product 2",
        price: 24.99,
        imageUrl: "https://res.cloudinary.com/datnvhs9h/image/upload/v1693374973/enbiyg5geapioirlvjp6.jpg",
    },
    {
        id: 2,
        name: "Product 2",
        description: "Description for Product 2",
        price: 24.99,
        imageUrl: "https://res.cloudinary.com/datnvhs9h/image/upload/v1693374973/enbiyg5geapioirlvjp6.jpg",
    }, {
        id: 2,
        name: "Product 2",
        description: "Description for Product 2",
        price: 24.99,
        imageUrl: "https://res.cloudinary.com/datnvhs9h/image/upload/v1693374973/enbiyg5geapioirlvjp6.jpg",
    }, {
        id: 2,
        name: "Product 2",
        description: "Description for Product 2",
        price: 24.99,
        imageUrl: "https://res.cloudinary.com/datnvhs9h/image/upload/v1693374973/enbiyg5geapioirlvjp6.jpg",
    }, {
        id: 2,
        name: "Product 2",
        description: "Description for Product 2",
        price: 24.99,
        imageUrl: "https://res.cloudinary.com/datnvhs9h/image/upload/v1693374973/enbiyg5geapioirlvjp6.jpg",
    }, {
        id: 2,
        name: "Product 2",
        description: "Description for Product 2",
        price: 24.99,
        imageUrl: "https://res.cloudinary.com/datnvhs9h/image/upload/v1693374973/enbiyg5geapioirlvjp6.jpg",
    }, {
        id: 2,
        name: "Product 2",
        description: "Description for Product 2",
        price: 24.99,
        imageUrl: "https://res.cloudinary.com/datnvhs9h/image/upload/v1693374973/enbiyg5geapioirlvjp6.jpg",
    }, {
        id: 2,
        name: "Product 2",
        description: "Description for Product 2",
        price: 24.99,
        imageUrl: "https://res.cloudinary.com/datnvhs9h/image/upload/v1693374973/enbiyg5geapioirlvjp6.jpg",
    }, {
        id: 2,
        name: "Product 2",
        description: "Description for Product 2",
        price: 24.99,
        imageUrl: "https://res.cloudinary.com/datnvhs9h/image/upload/v1693374973/enbiyg5geapioirlvjp6.jpg",
    }, {
        id: 2,
        name: "Product 2",
        description: "Description for Product 2",
        price: 24.99,
        imageUrl: "https://res.cloudinary.com/datnvhs9h/image/upload/v1693374973/enbiyg5geapioirlvjp6.jpg",
    },
];

function ProductCatalogData() {
    return (
        <div className="product-catalog">
            {products.map((productCatalog) => (
                <ProductCatalog key={productCatalog.id} productCatalog={productCatalog} />
            ))}
        </div>
    );
}

export default ProductCatalogData;
