import React from "react";
import Sidebar from "./Sidebar";
import "./Header.css";
import "./index.css";
import ProductCatalogData from "./ProductCatalogData";
import Header from "./Header";

function CatalogMain() {
    return (
        <div className="WebPub" dir="rtl">
            <Header />
            <div className="container">
                <Sidebar />
                <ProductCatalogData />
            </div>
        </div>
    )
}

export default CatalogMain;


{/* <div className="container" dir="rtl">
            <div className="sidebar-container">
                <Sidebar />
            </div>
            <div className="main-container">
                <ProductCatalogData />
            </div>
    </div> */}